import React from "react";
// reactstrap components
import { Container } from "reactstrap";
import RecoverForm from "views/forms/RecoverForm";
import PrideNavHomepage from "components/Navbars/PrideNavHomepage";
import { withRouter } from "react-router";
import ReactNotification from 'react-notifications-component'
import { isValid } from "services/requests_homepage";

class RecoverPassword extends React.Component {

    state = {
        loading: true
    }

    componentDidMount() {
        isValid({ token: this.props.match.params.token, email: this.props.match.params.email }, 'recover')
            .then(() => { this.setState({ loading: false }); })
            .catch(error => {
                this.props.history.push('/not-found');
            })
    }

    render() {
        if (this.state.loading)
            return null;
        return (
            <>
                <ReactNotification className={`notification-pride`} />
                <PrideNavHomepage showMenus={false} />
                <Container className="modal-dialog modal-dialog-centered modal-app">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ paddingTop: "15%", fontSize: "40px" }}>
                                <span className="">•</span>Recuperar Password
                                </h5>
                        </div>
                        <div className="modal-body">
                            <RecoverForm color="admin" params={this.props.match.params} />
                        </div>
                    </div>
                </Container>
            </>
        )
    }
}

export default withRouter(RecoverPassword);
