import React from "react";
import PropTypes from "prop-types";
import { AddButton } from "components/Common";
import { Record, ArrowRight } from "components/icons";
import { Collapse, CardBody, Card, CardHeader } from "reactstrap";
import classnames from "classnames";
import { formatPrideDate } from "helpers";

class RecordItem extends React.Component {
  render() {
    const { collapse, record, toggle, addObservation, hasButton } = this.props;
    return (
      <Card
        style={{ marginBottom: "1rem" }}
        key={record.id}
        className={classnames({ active: collapse === record.id })}
      >
        <CardHeader onClick={() => toggle(record.id)}>
          <div className="svg-icon">
            <Record />
          </div>
          <div className="name">
            <span>•</span> {record.name}
          </div>
          <span
                className="name"
                style={{ position: "absolute", right: "120px", top: "18px" }}
              >
                <div
                  className="separator-h"
                  style={{ marginRight: "27px" }}
                >
                  •
                </div>
                <div className="date" style={{ marginRight: "-20px" }}>
                  {formatPrideDate(record.date)}
                </div>
              </span>


          {/* <div className="separator-h">•</div>
          <div className="date">{formatPrideDate(record.date)}</div> */}
          <div className="arrow">
            <ArrowRight />
          </div>
        </CardHeader>
        <Collapse isOpen={collapse === record.id}>
          <CardBody className="records-body">
            <p>{record.description}</p>
            {record.notes.map(note => {
              return (
                <p className="note" key={note.id}>
                  {note.description}
                </p>
              );
            })}
            {hasButton && (
              <div className="button-add">
                <AddButton
                  color={"blue"}
                  text="Adicionar observação"
                  onPress={addObservation}
                />
              </div>
            )}
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}

RecordItem.propTypes = {
  //collapse: PropTypes.number.isRequired,
  record: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  addObservation: PropTypes.func,
  hasButton: PropTypes.bool
};

export default RecordItem;
