import React, { useState } from "react";
import useForm from "react-hook-form";
import { Input } from "components/Common";
import { saveSection } from "services/requests_admin";
import { notify, throwErrorMessage } from "helpers";

const SectionForm = (props) => {
  const { register, handleSubmit, errors, setError } = useForm();
  const { color, toggleModal, reloadData, action, section } = props;
  const [submited, setSubmited] = useState(false);
  
  const onSubmit = (data) => {
    setSubmited(true);
    data["content_type"] = "homepage";
    if (action === "edit") data["id"] = section.id;
    data["action"] = action;
    data["content_for"] = section.content_for;
    saveSection(data)
      .then((response) => {
        reloadData(false); // reload data on list view
        notify("success", response.message);
        toggleModal();
      })
      .catch((response) => {
        throwErrorMessage(response, setError);
        setSubmited(false);
      });
  };

  const urlButton = require("assets/img/buttons/add-admin-2@2x.png");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!["newsletter", "footer"].includes(section.content_for) && (
        <Input
          type="text"
          placeholder=""
          label="Cabeçalho *"
          id="title"
          name="title"
          error={errors.title}
          register={register}
          defaultValue={action === "edit" ? section.header : ""}
        />
      )}

      <Input
        type="textarea"
        rows={10}
        label="Descrição *"
        id="detail"
        name="detail"
        error={errors.detail}
        register={register}
        defaultValue={action === "edit" ? section.description : ""}
      />

      {section.content_for === "main" && (
        <Input
          type="text"
          placeholder=""
          label="URL do Vídeo"
          id="video_uri"
          name="video_uri"
          error={errors.video_uri}
          register={register}
          defaultValue={action === "edit" ? section.video_uri : ""}
        />
      )}

      <div className="button-form">
        <button disabled={submited} type="submit" className={`button-submit ${color}`}>
          <img src={urlButton} alt="guardar" />
          <div className="title">Guardar</div>
        </button>
      </div>
    </form>
  );
};

export default SectionForm;
