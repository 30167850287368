export function SetChat(user_id, chat_id) {
    return {
        type: 'SET_CHAT',
        payload: {
            user_id,
            chat_id,
        }
    }
}
export function ClearChat() {
    return {
        type: 'CLEAR_CHAT',
    }
}