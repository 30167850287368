import React, { useState } from "react"

import PrideNavHomepage from "components/Navbars/PrideNavHomepage"

import "./Questionnaire.scss"
import { ArrowLeft } from "react-feather"

import nextIcon from "../../assets/img/buttons/right-orange.png"
import Feedback from "views/Feedback"

import scrollToComponent from "react-scroll-to-component"

import AddTherapist from "../modals/AddTherapist.jsx"

import { useHistory } from "react-router-dom"

// Array of questions
const questions = [
  {
    title: "Como é a sua relação com a comida?",
    subText:
      "Por exemplo: a comida e a alimentação não geram nenhuma preocupação para si, ou é algo que está associado com preocupação e stress?",
    answers: [
      "Sem preocupação ou stress",
      "Um pouco problemática",
      "Moderadamente problemática",
      "Bastante problemática",
      "Com muita preocupação e stress"
    ]
  },

  {
    title:
      "O seu peso, corpo ou forma corporal fazem com que se sinta mal consigo?",
    subText:
      "Por exemplo: o número na balança, a forma do seu corpo ou uma parte do seu corpo.",

    answers: [
      "Nunca",
      "Poucas vezes",
      "Algumas vezes",
      "Bastantes vezes",
      "Sempre"
    ]
  },

  {
    title: "Sente que a comida, o peso ou a forma corporal dominam a sua vida?",
    subText:
      "Por exemplo: experienciando pensamentos constantes em torno da comida, peso ou do seu corpo.",
    answers: [
      "Nunca",
      "Poucas vezes",
      "Algumas vezes",
      "Bastantes vezes",
      "Sempre"
    ]
  },

  {
    title:
      "Sente ansiedade ou angústia quando não consegue controlar a sua alimentação?",
    subText:
      "Por exemplo: quando outras pessoas cozinham ou preparam a refeição para si, ou come fora.",
    answers: [
      "Nunca",
      "Poucas vezes",
      "Algumas vezes",
      "Bastantes vezes",
      "Sempre"
    ]
  },

  {
    title: `Alguma vez sentiu que não iria ser capaz 
      de parar de comer ou que perdeu o controlo sobre a alimentação?`,
    subText: `Por exemplo: sentimento de que não tem controlo sobre a comida, que come compulsivamente ou medo 
    que possa comer compulsivamente.`,
    answers: [
      "Nunca",
      "Poucas vezes",
      "Algumas vezes",
      "Bastantes vezes",
      "Sempre"
    ]
  },

  {
    title:
      "Quando pensa ter comido demasiado, faz alguma coisa para compensar?",
    subText:
      "Por exemplo: saltar a próxima refeição, comer menos na refeição seguinte, compensar com exercício físico, provocar vómito ou usar laxantes, diuréticos ou produtos dietéticos.",
    answers: [
      "Nunca",
      "Poucas vezes",
      "Algumas vezes",
      "Bastantes vezes",
      "Sempre"
    ]
  }
]

const Questionnaire = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1)
  const [answers, setAnswers] = useState([])
  const [showFeedback, updateShowFeedback] = useState(false)
  const [feedbackType, setFeedbackType] = useState(null)

  const [showModalTherapist, updateShowModalTherapist] = useState(false)

  const checkboxName = `question-${currentQuestionIndex}-checkboxes`

  let history = useHistory()
  // Function to handle next questions
  function nextQuestionHandler() {
    if (currentQuestionIndex === questions.length) {
      window.scrollTo(0, 0)

      const sumOfAllAnswersPoints = answers.reduce(
        (a, b) => a + Number(b.answer),
        0
      )

      const sumOfFirstTwoAnswersPoints =
        Number(answers[0].answer) + Number(answers[1].answer)

      const sumOfOtherAnswersPoints =
        Number(answers[2].answer) +
        Number(answers[3].answer) +
        Number(answers[4].answer) +
        Number(answers[5].answer)

      updateShowFeedback(true)

      if (
        sumOfAllAnswersPoints === 0 ||
        (sumOfFirstTwoAnswersPoints <= 1 && sumOfOtherAnswersPoints === 0)
      ) {
        return setFeedbackType("A")
      }

      if (sumOfFirstTwoAnswersPoints >= 2 || sumOfOtherAnswersPoints >= 1) {
        return setFeedbackType("B")
      }
    } else {
      window.scrollTo(0, 0)
      setCurrentQuestionIndex(currentQuestionIndex + 1)

      const answerCheckboxes = document.getElementsByName(checkboxName)
      Array.prototype.forEach.call(answerCheckboxes, (checkbox) => {
        checkbox.checked = false
      })
    }
  }

  // Function to handle back controller
  function prevQuestionHandler() {
    window.scrollTo(0, 0)
    setCurrentQuestionIndex(currentQuestionIndex - 1)
  }

  function checkboxHandler(e) {
    const answerCheckboxes = document.getElementsByName(checkboxName)

    Array.prototype.forEach.call(answerCheckboxes, (checkbox) => {
      if (checkbox.id !== e.target.id) {
        checkbox.checked = false
      }

      e.target.checked = true
    })

    const newAnswer = { question: currentQuestionIndex, answer: e.target.id }

    // check if question answer has already been stored in state
    let newAnswers = []

    const filter = answers.filter(
      (answer) => answer.question !== currentQuestionIndex
    )

    newAnswers.push(...filter)

    setAnswers([...newAnswers, newAnswer])
  }

  if (showFeedback) {
    return <Feedback type={feedbackType} answers={answers} />
  }

  function scrollSection(section) {
    history.push("/")
    scrollToComponent(this[section], {
      offset: -70,
      align: "top",
      duration: 500,
      ease: "linear"
    })
  }

  return (
    <>
      <AddTherapist
        visible={showModalTherapist}
        toggleModal={() => updateShowModalTherapist(!showModalTherapist)}
      />
      <div className="questionnaire">
        <PrideNavHomepage
          scrollSection={scrollSection}
          showMenus={true}
          addTherapist={() => updateShowModalTherapist(!showModalTherapist)}
        />

        <div className="question-ctn">
          {currentQuestionIndex > 1 && (
            <div className="voltar">
              <span onClick={prevQuestionHandler}>
                <ArrowLeft className="mr-2" size="15" strokeWidth="3" />
                Voltar
              </span>
            </div>
          )}
          <span className="px-2 py-1 rounded text-white label">
            Questionário de Triagem Rápido
          </span>
          <div className="question">
            <h1 className="font-weight-bold mr-2">{currentQuestionIndex}•</h1>
            <div>
              <h1 className="font-weight-bold">
                {questions[currentQuestionIndex - 1].title}
              </h1>

              <p>{questions[currentQuestionIndex - 1].subText}</p>
            </div>
          </div>
          <div className="answer">
            <ul>
              {questions[currentQuestionIndex - 1].answers.map((answer, i) => (
                <li key={i}>
                  <input
                    type="checkbox"
                    name={checkboxName}
                    id={i}
                    onClick={(e) => checkboxHandler(e)}
                  />
                  {answer}
                </li>
              ))}
            </ul>
          </div>
          <div className="control">
            <p className="number">
              {currentQuestionIndex}/{questions.length}
            </p>
            <div className="line"></div>

            {answers.length > currentQuestionIndex - 1 && (
              <button onClick={nextQuestionHandler}>
                <img src={nextIcon} alt="next-icon" />
                <p>Próximo</p>
              </button>
            )}
          </div>
        </div>

        <div className="footer-label">
          <p className="mt-5">Simulação resposta selecionada</p>
        </div>
      </div>
    </>
  )
}

export default Questionnaire
