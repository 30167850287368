import axios from "axios"
import { http } from "./http"
import { domainApi } from "./api"
import { convertToFormData } from "helpers"

const refreshToken = async (token) => {
  return axios
    .patch(domainApi + "auth/refresh", [], {
      headers: { Authorization: "Bearer " + token }
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

//Notices
const saveNotice = async (data) => {
  console.log(data)

  if (data.action === "edit") {
    data._method = "PATCH" //Workaround - ao enviar ficheiro se o pedido fosse POST não funciona corretamente.
    return http
      .post(`admin/notices/update/${data.id}`, convertToFormData(data), {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  } else {
    return http
      .post("admin/notices/store", convertToFormData(data), {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  }
}

const getNotices = async (page = 1, order, orderBy, search = null) => {
  let url

  url = `admin/notices?page=${page}&order=${order}&orderBy=${orderBy}&search=${search}`

  return http
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

const getNotice = async (id) => {
  return http
    .get(`admin/notices/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

//Events
const saveEvent = async (data) => {
  if (data.action === "edit") {
    data._method = "PATCH" //Workaround - ao enviar ficheiro se o pedido fosse POST não funciona corretamente.
    return http
      .post(`admin/events/update/${data.id}`, convertToFormData(data), {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  } else {
    return http
      .post("admin/events/store", convertToFormData(data), {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  }
}

const getEvents = async (page = 1, order, orderBy, search = null) => {
  let url

  url = `admin/events?page=${page}&order=${order}&orderBy=${orderBy}&search=${search}`

  return http
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

const getEvent = async (id) => {
  return http
    .get(`admin/events/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

//Faqs
const saveFaq = async (data) => {
  if (data.action === "edit") {
    return http
      .patch(`admin/contents/update/${data.id}`, data)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  } else {
    return http
      .post("admin/contents/store", data)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  }
}

const getFaqs = async (page = 1, order, orderBy, search = null) => {
  let url

  url = `admin/contents?page=${page}&content_type=faq&order=${order}&orderBy=${orderBy}&search=${search}`

  return http
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

const getFaq = async (id) => {
  return http
    .get(`admin/contents/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

//Users
const saveUser = async (data) => {
  if (data.action === "edit") {
    data._method = "PATCH" //Workaround - ao enviar ficheiro se o pedido fosse POST não funciona corretamente.
    return http
      .post(`admin/users/update/${data.id}`, convertToFormData(data), {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  } else {
    return http
      .post("admin/users/store", convertToFormData(data), {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  }
}

const getUsers = async (page = 1, order, orderBy, search = null) => {
  let url

  url = `admin/users?page=${page}&order=${order}&orderBy=${orderBy}&search=${search}`

  return http
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

const getUser = async (id) => {
  return http
    .get(`admin/users/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

//Sections
const saveSection = async (data) => {
  if (data.action === "edit") {
    return http
      .patch(`admin/contents/update/${data.id}`, data)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  } else {
    return http
      .post("admin/contents/store", data)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  }
}

const getSections = async (
  page = 1,
  order = "id",
  orderBy = "desc",
  search = null
) => {
  let url

  orderBy = "priority"

  url = `admin/contents?paginate=-1&content_type=homepage&order=${order}&orderBy=${orderBy}`

  return http
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

const getSection = async (id) => {
  return http
    .get(`admin/contents/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

//Delete
const deleteObject = async (data) => {
  let url

  switch (data.object) {
    case "contents":
      url = `admin/contents/${data.id}`
      break
    case "notices":
      url = `admin/notices/${data.id}`
      break
    case "events":
      url = `admin/events/${data.id}`
      break
    default:
      url = null
      break
  }

  return http
    .delete(url)
    .then((response) => {
      return response.message
    })
    .catch((error) => {
      throw error
    })
}

const getDegreeLevels = async () => {
  return http
    .get(`shared/degree_levels`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

const getTherapists = async () => {
  let url

  url = `admin/therapists`

  return http
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

const getPatientsToExport = async () => {
  let url

  url = `admin/patients?to-export-selectlist=1`

  return http
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

const getQuestionnairesToExport = async () => {
  let url

  url = `admin/questionnaires?to-export-selectlist=1`

  return http
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export {
  refreshToken,
  saveNotice,
  getNotices,
  getNotice,
  saveEvent,
  getEvents,
  getEvent,
  saveFaq,
  getFaqs,
  getFaq,
  saveUser,
  getUsers,
  getUser,
  getTherapists,
  saveSection,
  getSections,
  getSection,
  deleteObject,
  getDegreeLevels,
  getPatientsToExport,
  getQuestionnairesToExport
}
