import React, { memo } from 'react'
import { Calendar as ReactCalendar } from 'react-calendar';
import moment from 'moment'
import 'moment/locale/pt'

moment.locale('pt')

const _formatShortWeekday = (locale, value) => {
    return  ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'][value.getDay()];
}
const _formatMonth = (locale, value) => {
    return (moment(value).format('MMMM YYYY'))
}

const Calendar = memo(({events, onChange, defaultValue, selectDay}) => {
    return (
        <ReactCalendar 
        defaultValue={defaultValue}
        tileClassName={({date, view}) => {
            let parseDate = moment(date).format('YYYY-MM-DD')
            if(view === 'month' && (events || []).includes(parseDate)) 
                return "react-calendar__tile--event";
            else {
                return "react-calendar__tile--inactive";
            }
        }}
        onChange={onChange} 
        prev2Label={null} 
        next2Label={null} 
        className="events-calendar" 
        formatShortWeekday={_formatShortWeekday} 
        formatMonthYear={_formatMonth} 
        onClickDay={selectDay} 
        view={"month"} />
    )
})

export { Calendar }