import React from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Filters extends React.Component {
    render() {
        const { filters, applyFilter } = this.props;
        const filtersLen = filters.length;

        return (
            <React.Fragment>
                {filters.map((filter, index) => {
                    const itemClass = classnames({
                        'item': true,
                        'active': (filter.selected === true)
                    })
                    let Separator = null;
                    if(filtersLen !== index + 1) {
                        Separator = <span className="div">•</span>;
                    }
                    return (
                        <React.Fragment key={index}>
                            <button onClick={() => applyFilter(filter.orderBy)}>
                                <span className={itemClass}>{filter.name}</span>
                            </button>
                            {Separator}
                        </React.Fragment>
                    )
                })}
            </React.Fragment>
        )
    }
}

Filters.propTypes = {
    filters: PropTypes.array.isRequired,
    applyFilter: PropTypes.func.isRequired,
}

export { Filters };