import React from "react";
import LibraryPatient from "views/platform/Patient/Library";
import LibraryTherapist from "views/platform/Therapist/Library"

class Library extends React.Component {
    render() {
        const { role } = this.props;
        if(role === 'patient') {
            return <LibraryPatient {...this.props} />
        }
        if(role === 'therapist') {
            return <LibraryTherapist {...this.props} />
        }
        return null;
    }
}

export default Library;