import React, { memo } from 'react';

const Questionnaires = memo((props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width == null ? 51 : props.width} height={props.height == null ? 51 : props.height} viewBox="0 0 51 51">
            <g fill={props.fill == null ? "#b3021c" : props.fill} fillRule="evenodd">
                <path d="M35.826 13.565a1.74 1.74 0 0 1 1.741 1.74v25.567c0 .96-.778 1.74-1.74 1.74h-22.09A1.739 1.739 0 0 1 12 40.871V15.305c0-.96.779-1.74 1.737-1.74l5.481-.001a2.77 2.77 0 0 0-.612 1.74c0 .66.23 1.265.612 1.741l-3.74-.001v22.089H34.09V17.044l-4.264.001a2.77 2.77 0 0 0 .611-1.74 2.77 2.77 0 0 0-.612-1.741l6.002.001z"/>
                <path d="M21.389 14.261a1.044 1.044 0 0 0 0 2.087h6.265a1.044 1.044 0 0 0 0-2.087 1.74 1.74 0 0 1-1.741-1.739v-1.044h-2.087v1.044c0 .96-.78 1.74-1.737 1.74h-.7zm6.265 5.566h-6.265a4.528 4.528 0 0 1-4.52-4.523 4.532 4.532 0 0 1 3.479-4.4V9.74c0-.96.778-1.739 1.74-1.739h5.566c.958 0 1.737.778 1.737 1.74v1.39a4.53 4.53 0 0 1 2.782 4.175 4.525 4.525 0 0 1-4.52 4.522zM29.566 36.67h-9.74c-.962 0-1.74-.79-1.74-1.75s.778-1.75 1.74-1.75h9.74c.962 0 1.736.79 1.736 1.75s-.774 1.75-1.736 1.75M29.566 30.982h-9.74c-.962 0-1.74-.79-1.74-1.75s.778-1.75 1.74-1.75h9.74c.962 0 1.736.79 1.736 1.75s-.774 1.75-1.736 1.75"/>
            </g>
        </svg>
    )
})

export { Questionnaires }