import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";

export default class Page404 extends React.Component {
    render() {
        return(
            <>
            <div className="app">
                <div className="position-relative">
                {/* shape Hero */}
                <section className={`section section-shaped section-404`} style={{paddingTop: 0, paddingBottom:0}}>
                    <div className={`shape shape-style-1 shape-404`}>
                        <span className="top-left" />
                        <span className="top-right" />
                        <span className="top-right-2" />
                        <span className="bottom-right" />
                        <span className="bottom-left" />
                    </div>
                    <div className="logo">
                        <Link to="/">
                            <img alt="logo" src={require("assets/img/brand/logo-bold/logotipo.png")} />
                        </Link>
                    </div>
                    <Container className="container-404">
                        <div className="centered-content">
                            <div className="image">
                                <span>4</span>
                                <img src={require("assets/img/404/spinner.png")} alt="404" />
                                <span>4</span>
                            </div>
                            <div className="text">
                                Página < br />
                                não encontrada
                                <Link to="/" className="link-return">
                                    <div className="content">
                                        <img src={require("assets/img/buttons/back.png")} alt="back" width="50" />
                                        <span> Voltar à página inicial </span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </Container>
                </section>
                </div>
            </div>
            </>
        )
    }
}