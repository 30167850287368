import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { SetRefreshToken, SetProfile, SetLogout } from "store/actions/auth.actions";
import routes from './routes';
import PrideNavApp from "components/Navbars/PrideNavApp.jsx";
import { http } from "services";
import LoadingBar from 'react-top-loading-bar';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

class AppLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            boot: false,
            authenticated: false,
            user: {},
            isLoading: false
        }
    }
    componentDidMount() {
        this._checkAuth();
    }
    _checkAuth = async () => {
        let token = JSON.parse(window.localStorage.getItem('access_token'));
        if (token) {
            await this.props.SetRefreshToken(token);

            await http.get("auth/me")
                .then(response => {
                    this.setState({
                        authenticated: true,
                        boot: true,
                        user: response.data
                    }, () => {
                        let role = response.data.roles[0];
                        let super_admin = (role === "super_admin") ? true : false;
                        if (super_admin) {
                            this._signOut();
                        }
                        this.props.SetProfile(
                            true,
                            super_admin,
                            response.data.id,
                            response.data.name,
                            response.data.email,
                            response.data.avatar,
                            response.data.info,
                            response.data.address,
                            response.data.color,
                            role
                        )
                    })

                })
                .catch(error => {
                    this._signOut();
                    console.log(error);
                })
        } else {
            this.setState({ boot: true }, () => {
                this.props.history.push('/')
            });
        }
    }
    _signOut = async () => {
        http.post("auth/logout").then(response => {
            this.props.history.push('/')
            this.props.SetLogout();
        })
    }
    _startProgressBar = (updateComp = true) => {
        this.LoadingBar.continuousStart()
        if (updateComp) {
            this.setState({ isLoading: true })
        }
    }
    _finishProgressBar = (updateComp = true) => {
        this.LoadingBar.complete();
        if (updateComp) {
            this.setState({ isLoading: false })
        }
    }
    _redirect = (role) => {
        if (role === 'therapist') {
            return (<Redirect from="/app" to="/app/patients" />)
        }
        if (role === 'patient') {
            return (<Redirect from="/app" to="/app/library" />)
        }
        if (role === 'scientific_community') {
            return (<Redirect from="/app" to="/app/content" />)
        }
        return null;
    }
    render() {
        const { user, boot, isLoading } = this.state;
        const { role } = this.props.auth;
        if (!boot) {
            return null;
        }
        return (            
            <React.Fragment>
                <ReactNotification className={`notification-pride ${user.color}`} />
                <LoadingBar className={`loading-bar ${user.color}`} onRef={ref => (this.LoadingBar = ref)} />
                <PrideNavApp user={user} signOut={this._signOut} isLoading={isLoading} />
                <main ref="main">
                    <Switch>
                        {routes.map((route, idx) => {
                            return route.component ? (
                                <MiddlewareRoute key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    available={route.role}
                                    role={role}
                                    history={this.props.history}
                                    render={props => (
                                        <route.component {...props} color={user.color} user={user} role={role} startPB={this._startProgressBar} finishPB={this._finishProgressBar} isLoading={isLoading} />
                                    )} />
                            ) : (null);
                        })}
                        {this._redirect(role)}
                    </Switch>
                </main>
            </React.Fragment>
        )
    }
}
// middleware to verify if the assigned auth user can jump to the route
const MiddlewareRoute = ({ role, available, history, ...rest }) => {
    if (role && !available.includes(role)) {
        return <Redirect to="/app/" />
    }
    return <Route {...rest} />
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ SetRefreshToken, SetProfile, SetLogout }, dispatch);
}

let AppLayoutComp = connect(mapStateToProps, mapDispatchToProps)(AppLayout);
export default withRouter(AppLayoutComp)