import React from "react";
import { Modal } from "reactstrap";
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import AddTherapistForm from "views/forms/AddTherapistForm";

class AddTherapist extends React.Component {
    state = {
        visible: false,
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if(prevState.visible !== nextProps.visible) {
            return ({ visible: nextProps.visible })
        }
        return null;
    }
    render() {
        if(this.state.visible === false) {
            return null;
        }
        const { toggleModal } = this.props;
        return (
            <>
            {this.state.visible &&
                <Fade duration={300}>
                <button className="button-close-modal-pride" aria-label="Close" data-dismiss="modal" type="button" onClick={() => toggleModal()}>
                    <img src={require("assets/img/buttons/close.png")} alt="close" width={97} height={92} />
                </button>
                </Fade>
            }
            <Modal className="modal-dialog-centered modal-app" modalClassName="animated fadeIn" isOpen={this.state.visible} fade={false} backdrop="static" toggle={() => toggleModal()}>
                <div className="modal-header">
                    <h5 className="modal-title"><span className="red">•</span>Registar Terapeuta</h5>
                </div>
                <div className="modal-body">
                    <AddTherapistForm color="red" toggleModal={toggleModal} />
                </div>
            </Modal>
            </>
        )
    }
}

AddTherapist.propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
}

export default AddTherapist