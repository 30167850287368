import React, { useState } from "react";
import useForm from "react-hook-form";
import { Input } from "components/Common";
import axios from "axios";
import { resetUrl } from "services";
import { notify, throwErrorMessage } from "helpers";
import { withRouter } from "react-router-dom";

const RecoverForm = (props) => {
  const { register, handleSubmit, errors, setError } = useForm();
  const [submited, setSubmited] = useState(false);
  const { color } = props;
  const { params } = props;

  const onSubmit = (data) => {
    setSubmited(true);
    data["token"] = params.token;
    axios
      .post(resetUrl, data)
      .then((response) => {
        notify("success", "Password alterada com sucesso.");
        setTimeout(() => {
          window.location.href = window.location.origin;
        }, 2000);
      })
      .catch((error) => {
        setSubmited(false);
        if (error.response.status === 422)
          throwErrorMessage(
            {
              errors: error.response.data.errors,
              status: error.response.status,
            },
            setError
          );
        else props.history.push("/not-found");
      });
  };

  const urlButton = require("assets/img/buttons/right-" + color + "@2x.png");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        defaultValue={params.email}
        type="email"
        placeholder="email de utilizador"
        label="Email *"
        id="email"
        name="email"
        error={errors.email}
        register={register({
          required: "Campo é obrigatório!",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: "email é inválido",
          },
        })}
      />

      <Input
        type="password"
        placeholder=""
        label="Nova password *"
        id="password"
        name="password"
        error={errors.password}
        register={register({ required: "Campo é obrigatório" })}
      />

      <Input
        type="password"
        placeholder=""
        label="Confirmar password *"
        id="password_confirmation"
        name="password_confirmation"
        error={errors.password_confirmation}
        register={register({ required: "Campo é obrigatório" })}
      />

      <div className="button-form">
        <button
          disabled={submited}
          type="submit"
          className={`button-submit ${color}`}
        >
          <img src={urlButton} alt="login" />
          <div className="title">Alterar</div>
        </button>
      </div>
    </form>
  );
};

export default withRouter(RecoverForm);
