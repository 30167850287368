import React, { memo } from 'react'
import { Button as ButtonStrap } from "reactstrap";
import classnames from 'classnames'

const Button = memo(({onPress, color, minWidth, fontSize, text}) => {
    const style = {
        minWidth: minWidth + "px",
        fontSize: fontSize + "px",
    }
    const classNames = classnames({
        "btn-pride": true,
        [color]: color
    })
    return (
        <ButtonStrap type="button" className={classNames} onClick={onPress} style={style}>
            {text}
        </ButtonStrap> 
    )
})

export { Button };