import { store } from "react-notifications-component"
import React from "react"
import { Check, X } from "react-feather"
import moment from "moment"
import ReactHtmlParser from "react-html-parser"

/**
 * handle global input change on react component
 * @param {event} event of input
 * @param {object} this of the origin component
 */
const handleInputChange = (event, self) => {
  const target = event.target
  const value = target.type === "checkbox" ? target.checked : target.value
  const name = target.name

  self.setState({ [name]: value })
}
/**
 * dispatch notification in the plataform
 * @param {string} type of the notification to dispatch
 * @param {string} message of notification
 */
const notify = (type = null, message, duration = 5000) => {
  let content = ""
  if (type === "success") {
    content = function () {
      return (
        <div className="notification">
          <Check size={50} />
          <div className="message">{ReactHtmlParser(message)}</div>
        </div>
      )
    }
  } else if (type === "error") {
    content = function () {
      return (
        <div className="notification">
          <X size={50} />
          <div className="message">{ReactHtmlParser(message)}</div>
        </div>
      )
    }
  } else {
    content = function () {
      return (
        <div className="notification">
          <div className="message">{ReactHtmlParser(message)}</div>
        </div>
      )
    }
  }
  store.addNotification({
    content,
    message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: duration
    }
  })
}
/**
 * dispatch error from api request
 * @param {object} object with error details
 * @param {func} function to set error on the specific form if provided
 */
const throwErrorMessage = (object, setError = null) => {
  if (object.status === 422 || object.status_code === 422) {
    let keys = Object.keys(object.errors)
    keys.forEach((key) => {
      if (setError) setError(key, null, object.errors[key][0])
      switch (key) {
        case "thumbnail":
          notify("error", "Tem que definir a imagem.")
          break
        case "avatar":
          notify("error", "Tem que definir o avatar.")
          break
        default:
      }
    })
  } else {
    if (object.message) notify("error", object.message)
  }
}
/**
 * format date to the pride app platform
 * @param {string} string of date to parse
 */
const formatPrideDate = (date, appendSpan = false) => {
  const parseDate = moment(date, "DD-MM-YYYY")
  if (appendSpan === true)
    return (
      parseDate.format("DD") +
      "<span>•</span>" +
      parseDate.format("MM") +
      "<span>•</span>" +
      parseDate.format("YYYY")
    )
  return (
    parseDate.format("DD") +
    "•" +
    parseDate.format("MM") +
    "•" +
    parseDate.format("YYYY")
  )
}

/**
 * convert an object to a form data
 * @param {object} object of json to convert
 */
const convertToFormData = (object) => {
  var formData = new FormData()
  for (var key in object) {
    formData.append(key, object[key])
  }
  return formData
}

/**
 * filter an array by a keyword
 * @param {array} array to filter
 * @param {string} string to search
 */
const filterByValue = async (array, string) => {
  return array.filter(function (o) {
    if (o != null)
      return Object.keys(o).some(function (k) {
        if (o[k] != null)
          return o[k]
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(
              string
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            )
      })
  })
}
/**
 * transform array of conversations to an functional object conversation
 * @param {array} conversation to transform
 */
const transformChatObject = (conversation) => {
  let arrayConversation = []
  let user_id = null
  conversation.forEach((message) => {
    if (user_id !== message.user.id) {
      let newGroupMsg = {
        user_id: message.user.id,
        avatar: message.user.avatar,
        color: message.user.color,
        name: message.user.name,
        messages: [].concat(message)
      }
      arrayConversation = arrayConversation.concat(newGroupMsg)
    } else {
      let groupMsgId = arrayConversation
        .map((el) => el.user_id === message.user.id)
        .lastIndexOf(true)
      if (typeof arrayConversation[groupMsgId].messages !== "undefined") {
        arrayConversation[groupMsgId].messages.push(message)
      }
    }
    user_id = message.user.id
    //console.log(message)
  })

  return arrayConversation
}
/**
 * attachs file to form data
 * @param {object} object of json to convert
 */
const handleFile = (files, setArticleFile, setLabel) => {
  if (files[0]) {
    if (files[0].size < 10485760) {
      // lower than 5 mb
      setArticleFile(files[0])
      setLabel(files[0].name)
    }
  }
}

const setOpacityBackdropModal = (opacity) => {
  try {
    document
      .getElementsByClassName("modal-backdrop")[0]
      .style.setProperty("opacity", opacity, "important")
  } catch (e) {}
}

export {
  handleInputChange,
  notify,
  throwErrorMessage,
  formatPrideDate,
  convertToFormData,
  filterByValue,
  transformChatObject,
  handleFile,
  setOpacityBackdropModal
}
