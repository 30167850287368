import React from "react"

import { Modal } from "reactstrap"
import Fade from "react-reveal/Fade"
import AssessmentForm from "views/forms/AssessmentForm"

import "./Assessment.scss"

const Assessment = ({ visible, toggleModal, answers }) => {
  return (
    <>
      {visible && (
        <Fade duration={300}>
          <button
            className="button-close-modal-pride"
            aria-label="Close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            <img
              src={require("assets/img/buttons/close.png")}
              alt="close"
              width={97}
              height={92}
            />
          </button>
        </Fade>
      )}

      <Modal
        className="modal-dialog-centered modal-app"
        modalClassName="animated fadeIn"
        isOpen={visible}
        fade={false}
        backdrop="static"
        toggle={() => toggleModal()}
      >
        <div className="modal-header mb-0">
          <h5 className="modal-title">
            <span className="orange">•</span>Completar Avaliação
          </h5>
        </div>
        <div className="modal-body mx-0 mt-3 px-3">
          <span style={{ letterSpacing: "1.5px" }}>
            Enthusiastically conceptualize multimedia based deliverables
            vis-a-vis enterprise solutions. Credibly transition sustainable
            functionalities after stand-alone mindshare. Phosfluorescently
            myocardinate.
          </span>

          <h6 className="mt-3 font-weight-900">
            Após introduzir os seus dados irá receber a sua avaliação
          </h6>

          <AssessmentForm toggleModal={toggleModal} answers={answers} />
        </div>
      </Modal>
    </>
  )
}

export default Assessment
