import React from "react";
// reactstrap components
import { Modal } from "reactstrap";
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import LoginForm from "views/forms/LoginForm";

class LoginModal extends React.Component {
    state = {
        visible: false,
        details: {}
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.visible !== nextProps.visible) {
            return ({ visible: nextProps.visible, details: nextProps.details })
        }
        return null;
    }

    render() {
        if (this.state.visible === false) {
            return null;
        }
        const { toggleLogin, sectionLogin } = this.props;

        const changeDetails = (details) => {
            this.setState({ details: details })
        }

        return (
            <>
                {this.state.visible &&
                    <Fade duration={300}>
                        <button className="button-close-modal-pride" aria-label="Close" data-dismiss="modal" type="button" onClick={() => toggleLogin()}>
                            <img src={require("assets/img/buttons/close.png")} alt="close" width={97} height={92} />
                        </button>
                    </Fade>
                }
                <Modal className="modal-dialog-centered modal-app" modalClassName="animated fadeIn" isOpen={this.state.visible} fade={false} backdrop="static" toggle={() => toggleLogin()}>
                    <div className="modal-header">
                        <h5 className="modal-title"><span className={this.state.details.color}>•</span>{this.state.details.title}</h5>
                        {this.state.details.subtitle &&
                            <div className="subtitle" style={{width: "100%"}}>{this.state.details.subtitle}</div>
                        }
                    </div>
                    <div className="modal-body">
                        <LoginForm color={this.state.details.color} changeDetails={changeDetails} details={this.state.details} toggleLogin={toggleLogin} sectionLogin={sectionLogin}/>
                    </div>
                </Modal>
            </>
        )
    }
}

LoginModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleLogin: PropTypes.func.isRequired,
    details: PropTypes.object,
    sectionLogin: PropTypes.string
}

export default LoginModal;
