import React, { memo } from 'react';

const Close = memo(() => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
            <path fill="#434343" fillRule="nonzero" d="M36.97 1.208a4.116 4.116 0 0 1 5.826 0 4.12 4.12 0 0 1-.001 5.822L27.823 22l14.973 14.972a4.122 4.122 0 0 1-.005 5.826 4.12 4.12 0 0 1-5.819-.004L22 27.822 7.024 42.798A4.111 4.111 0 0 1 4.117 44a4.098 4.098 0 0 1-2.913-1.207 4.12 4.12 0 0 1 .001-5.823L16.176 22 1.204 7.029a4.12 4.12 0 0 1 0-5.821 4.116 4.116 0 0 1 5.824-.002L22 16.176 36.97 1.209z"/>
        </svg>

    )
})

export { Close }