import React from 'react'
import PropTypes from 'prop-types';
import { ArrowRight, ArrowLeft } from "components/icons"
import classnames from 'classnames';

class Paginator extends React.Component {
    render() {
        const { color, currentPage, maxPage, movePage, render_icon, classProps, styleProps, icon } = this.props;
        const classPrev = classnames({
            'arrow-left': true,
            'inactive': (currentPage === 1)
        })
        const classNext = classnames({
            'arrow-right': true,
            'inactive': (currentPage === maxPage)
        })
        return (
            <div className={`pagination-list m-t-30 ${color} ${classProps}`} style={styleProps}>
                <div className="counter">
                    {currentPage} / {maxPage}
                </div>
                <div className="separator-pag" />
                {render_icon === true &&
                    <>
                        <img src={icon.url} alt={icon.label} style={{
                            cursor: "pointer"
                        }} onClick={() => movePage('right')} />
                        <div className="title" style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            marginTop: "-5px",
                        }}>{icon.label}</div>
                    </>
                }
                {render_icon !== true &&
                    <div className="arrows">
                        <button className={classPrev} onClick={() => movePage('left')}>
                            <ArrowLeft />
                        </button>
                        <button className={classNext} onClick={() => movePage('right')}>
                            <ArrowRight />
                        </button>
                    </div>
                }
            </div>
        )
    }
}

Paginator.propTypes = {
    color: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    maxPage: PropTypes.number.isRequired,
    movePage: PropTypes.func.isRequired,
}

export { Paginator };