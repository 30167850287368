import React, { useState } from "react"

import PrideNavHomepage from "components/Navbars/PrideNavHomepage"

import "./Feedback.scss"
import { useHistory } from "react-router-dom"
import Assessment from "views/modals/Assessment/Assessment"

const feedbacks = [
  {
    title: (
      <h3 className="font-weight-bold">
        As suas respostas indicam que, no momento presente, tem um{" "}
        <span>risco baixo</span> de desenvolver uma perturbação alimentar.
      </h3>
    ),

    subText: `
        No entanto, algumas pessoas que completam estas questões de triagem,
        fazem-no por estarem preocupadas com sua saúde e bem-estar. Para mais
        informações sobre perturbações alimentares, por favor aceda aos nossos
        materiais de apoio.`
  },

  {
    title: (
      <h3 className="font-weight-bold">
        As suas respostas indicam que, no momento presente,{" "}
        <span>poderá estar a experienciar sintomas</span> observados em pessoas
        com perturbações alimentares. É essencial que não ignore isto.
      </h3>
    ),

    subText: `No entanto, algumas pessoas que completam estas questões de triagem,
        fazem-no por estarem preocupadas com sua saúde e bem-estar. Para mais
        informações sobre perturbações alimentares, por favor aceda aos nossos
        materiais de apoio.`
  }
]

const Feedback = ({ type, answers }) => {
  const [showModal, updateShowModal] = useState(false)

  const history = useHistory()
  return (
    <>
      <Assessment
        visible={showModal}
        toggleModal={() => updateShowModal(!showModal)}
        answers={answers}
      />
      <div className="feedback">
        <PrideNavHomepage showAvaliar={true} />

        <div className="feedback-ctn">
          <span className="px-2 py-1 rounded text-white label">
            Questionário de Triagem Rápido
          </span>

          {type === "A" ? feedbacks[0].title : feedbacks[1].title}

          <p>{type === "A" ? feedbacks[0].subText : feedbacks[1].subText}</p>

          <div className="text-center btns-ctn">
            {type === "A" && (
              <button
                className="py-2 rounded d-block"
                onClick={() => history.push("/")}
              >
                Saber+
              </button>
            )}

            {type === "B" && (
              <>
                <button
                  className="py-2 rounded d-block"
                  onClick={() => updateShowModal(true)}
                >
                  Completar Avaliação
                </button>
                <button
                  className="py-2 rounded d-block unidades"
                  onClick={() => history.push("/new#unidades")}
                >
                  Unidades de Tratamento
                </button>
              </>
            )}
          </div>
        </div>

        <div className="footer-label">
          <div className="mt-5">
            <p>para efeitos de análise</p>
            <p>interna clicar aqui para</p>
            <p>o versão feedback B</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Feedback
