import React from "react";
import { Container, Row, Col, ListGroup } from "reactstrap";
import { Paginator, Filters } from "components/Common";
import {
  AddUser,
  Questionnaires as QuestionnairesIcon,
} from "components/icons";
import { getPatients } from "services/requests";
import { throwErrorMessage } from "helpers";
import Fade from "react-reveal/Fade";
import AddPatient from "views/modals/AddPatient.jsx";
import PatientItem from "views/platform/Therapist/PatientItem";
import { withRouter } from "react-router";

class Clinic extends React.Component {
  state = {
    modalAddPatient: false,
    patients: [],
    current_page: 1,
    max_page: 1,
    loading: false,
    filters: [
      // order flags
      { order: "desc", orderBy: "name", name: "nome", selected: false },
      {
        order: "desc",
        orderBy: "created_at",
        name: "data de início",
        selected: true,
      },
    ],
  };
  componentDidMount() {
    this._fetchPatients();
  }
  _toggleModalPatient = () => {
    this.setState({ modalAddPatient: !this.state.modalAddPatient });
  };
  _fetchPatients = async (showBar = true) => {
    const { startPB, finishPB } = this.props;
    await this.setState({ loading: true }, () => {
      if (showBar) {
        startPB();
      }
    });
    const { current_page, filters } = this.state;
    const filter = filters.find((el) => el.selected === true);
    // get patients
    await getPatients(current_page, filter.order, filter.orderBy)
      .then((response) => {
        this.setState(
          {
            patients: response.data,
            max_page: response.meta.last_page,
            loading: false,
          },
          () => {
            if (showBar) {
              finishPB();
            }
          }
        );
      })
      .catch((error) => {
        throwErrorMessage(error);
      });
  };
  _movePage = (dir) => {
    let { current_page } = this.state;
    let value = dir === "left" ? current_page - 1 : current_page + 1;
    this.setState({ current_page: value }, () => {
      this._fetchPatients(false); // should not simulate progress bar
    });
  };
  _filterGrid = (key) => {
    let { filters } = this.state;
    const filterIndex = filters.findIndex((el) => el.orderBy === key);
    // find current selected index
    const selectedIndex = filters.findIndex((el) => el.selected === true);

    filters[selectedIndex].selected = false;
    filters[filterIndex].order =
      filters[filterIndex].order === "asc" ? "desc" : "asc";
    filters[filterIndex].selected = true;

    this.setState({ filters }, () => {
      this._fetchPatients(false);
    }); // update state
  };
  render() {
    const {
      patients,
      filters,
      modalAddPatient,
      current_page,
      max_page,
    } = this.state;
    const { color, isLoading } = this.props;
    if (isLoading) {
      return <div className="loading-div"></div>;
    }
    return (
      <div className="position-relative">
        {/* shape Hero */}
        <section className={`section section-shaped section-app`}>
          <div className={`shape shape-style-1 shape-app ${color}`}>
            <span className="bottom-left" />
            <span className="bottom-right" />
          </div>
          <Fade>
            <Container className="d-flex container-xl">
              <Row style={{ width: "100%" }}>
                <Col lg="10" className="col-patients" md="12">
                  <div className={`filters-list blue`}>
                    <strong>ordenar por:</strong>
                    <Filters filters={filters} applyFilter={this._filterGrid} />
                  </div>
                  <ListGroup className="list-patients" flush>
                    {patients.map((patient) => {
                      return <PatientItem patient={patient} key={patient.id} />;
                    })}
                  </ListGroup>
                  <Paginator
                    color={"blue"}
                    currentPage={current_page}
                    maxPage={max_page}
                    movePage={this._movePage}
                  />
                </Col>
              </Row>
            </Container>
          </Fade>
          <div className="menu-app">
            <Fade>
              <button
                className="button-menu"
                onClick={() => this._toggleModalPatient()}
              >
                <div className="container-icon">
                  <div className="svg-icon">
                    <AddUser />
                  </div>
                </div>
                <div className="text-button">
                  <span>•</span> Novo Paciente
                </div>
              </button>
              <button
                className="button-menu"
                onClick={() => {
                  this.props.history.push("/app/questionnaires");
                }}
              >
                <div className="container-icon">
                  <div className="svg-icon">
                    <QuestionnairesIcon />
                  </div>
                </div>
                <div className="text-button">
                  <span>•</span> Ferramentas de Avaliação
                </div>
              </button>
            </Fade>
          </div>
        </section>
        {/* Modals */}
        <AddPatient
          visible={modalAddPatient}
          toggleModal={this._toggleModalPatient}
          color={"blue"}
          reloadData={this._fetchPatients}
        />
      </div>
    );
  }
}

export default withRouter(Clinic);
