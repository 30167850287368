import React, { memo } from 'react';

const AddAdmin = memo(() => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="-10 -8 51 51">
            <path fill="#939393" fillRule="evenodd" d="M14.161 14.218V2.596c0-1.158.946-2.096 2.106-2.096 1.157 0 2.106.94 2.106 2.096l-.001 11.622h11.034c1.155 0 2.094.949 2.094 2.106a2.104 2.104 0 0 1-2.094 2.106l-11.034-.001v10.975a2.105 2.105 0 0 1-2.105 2.096 2.103 2.103 0 0 1-2.106-2.096V18.43H2.594A2.101 2.101 0 0 1 .5 16.325c0-1.159.936-2.105 2.094-2.105l11.567-.001z"/>
        </svg>

    )
})

export { AddAdmin }