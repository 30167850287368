import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"

import "assets/scss/pride.scss"
import "react-perfect-scrollbar/dist/css/styles.css"

import Index from "views/Index.jsx"
import AppLayout from "components/AppLayout/AppLayout"
import AdminLayout from "components/AdminLayout/AdminLayout"
import LoginAdmin from "views/admin/LoginAdmin"
import RecoverPassword from "views/other/RecoverPassword"
import Invite from "views/other/Invite"
import Page404 from "views/other/Page404"
import PageSoon from "views/other/PageSoon"
import MobileRestricted from "views/mobile/MobileRestricted"
import Sabermais from "views/Sabermais"

import { Provider } from "react-redux"

import store from "store/store"
import PageMaintenance from "views/other/PageMaintenance"
import Questionnaire from "views/Questionnaire"

if (JSON.parse(process.env.REACT_APP_IN_MAINTENANCE)) {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => <PageMaintenance {...props} />}
          />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  )
} else {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact render={(props) => <Index {...props} />} />
          <Route path="/app" render={(props) => <AppLayout {...props} />} />
          <Route
            path="/administrator"
            render={(props) => <LoginAdmin {...props} />}
          />
          <Route
            path="/recover/:email/:token"
            render={(props) => <RecoverPassword {...props} />}
          />
          <Route
            path="/invite/:email/:token"
            render={(props) => <Invite {...props} />}
          />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route
            path="/sabermais"
            render={(props) => <Sabermais {...props} />}
          />
          <Route
            path="/questionario"
            render={(props) => <Questionnaire {...props} />}
          />
          <Route
            path="/forbidden"
            render={(props) => <MobileRestricted {...props} />}
          />
          <Route path="/soon" render={(props) => <PageSoon {...props} />} />
          <Route name="Page 404" component={Page404} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  )
}
