import React, { useState } from "react"

import { Input } from "components/Common"
import emailjs from "emailjs-com"

import { notify } from "../../helpers"

import { useHistory } from "react-router-dom"

const questions = [
  {
    title: "Como é a sua relação com a comida?",
    subText:
      "Por exemplo: a comida e a alimentação não geram nenhuma preocupação para si, ou é algo que está associado com preocupação e stress?",
    answers: [
      "Sem preocupação ou stress",
      "Um pouco problemática",
      "Moderadamente problemática",
      "Bastante problemática",
      "Com muita preocupação e stress"
    ]
  },

  {
    title:
      "O seu peso, corpo ou forma corporal fazem com que se sinta mal consigo?",
    subText:
      "Por exemplo: o número na balança, a forma do seu corpo ou uma parte do seu corpo.",

    answers: [
      "Nunca",
      "Poucas vezes",
      "Algumas vezes",
      "Bastantes vezes",
      "Sempre"
    ]
  },

  {
    title: "Sente que a comida, o peso ou a forma corporal dominam a sua vida?",
    subText:
      "Por exemplo: experienciando pensamentos constantes em torno da comida, peso ou do seu corpo.",
    answers: [
      "Nunca",
      "Poucas vezes",
      "Algumas vezes",
      "Bastantes vezes",
      "Sempre"
    ]
  },

  {
    title:
      "Sente ansiedade ou angústia quando não consegue controlar a sua alimentação?",
    subText:
      "Por exemplo: quando outras pessoas cozinham ou preparam a refeição para si, ou come fora.",
    answers: [
      "Nunca",
      "Poucas vezes",
      "Algumas vezes",
      "Bastantes vezes",
      "Sempre"
    ]
  },

  {
    title: `Alguma vez sentiu que não iria ser capaz 
      de parar de comer ou que perdeu o controlo sobre a alimentação?`,
    subText: `Por exemplo: sentimento de que não tem controlo sobre a comida, que come compulsivamente ou medo 
    que possa comer compulsivamente.`,
    answers: [
      "Nunca",
      "Poucas vezes",
      "Algumas vezes",
      "Bastantes vezes",
      "Sempre"
    ]
  },

  {
    title:
      "Quando pensa ter comido demasiado, faz alguma coisa para compensar?",
    subText:
      "Por exemplo: saltar a próxima refeição, comer menos na refeição seguinte, compensar com exercício físico, provocar vómito ou usar laxantes, diuréticos ou produtos dietéticos.",
    answers: [
      "Nunca",
      "Poucas vezes",
      "Algumas vezes",
      "Bastantes vezes",
      "Sempre"
    ]
  }
]

const AssessmentForm = ({ answers, toggleModal }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [sending, updateSending] = useState(false)

  const [nameError, setNameError] = useState(undefined)
  const [emailError, setEmailError] = useState(undefined)

  let history = useHistory()

  function sendEmail(e) {
    e.preventDefault()

    updateSending(true)
    setNameError(undefined)
    setEmailError(undefined)

    if (name === "") {
      updateSending(false)
      setNameError({ message: "O nome é obrigatório" })

      return
    }

    if (email === "") {
      updateSending(false)
      setEmailError({ message: "E-mail é obrigatório" })

      return
    }

    const emailParams = {
      email,
      to_name: name,
      from_name: name,
      answer_1: questions[0].answers[answers[0].answer],
      answer_2: questions[1].answers[answers[1].answer],
      answer_3: questions[2].answers[answers[2].answer],
      answer_4: questions[3].answers[answers[3].answer],
      answer_5: questions[4].answers[answers[4].answer],
      answer_6: questions[5].answers[answers[5].answer],
      message:
        "As suas respostas indicam que, no momento presente, poderá estar a experienciar sintomas observados em pessoas com perturbações alimentares. É essencial que não ignore isto."
    }

    // TODO: Find way to add keys to .env in production
    emailjs
      .send(
        "service_s7dfrw6",
        "template_e8sw2bg",
        emailParams,
        "user_KeWrKVE8VifdML7qGL7Wv"
      )
      .then(
        (_result) => {
          updateSending(false)
          notify("success", "E-mail enviado com sucesso")
          history.push("/")
        },
        (_error) => {
          updateSending(false)
          notify("error", "Algo deu errado. Tente novamente!")
        }
      )
  }

  return (
    <form onSubmit={sendEmail}>
      <div>
        <Input
          type="text"
          placeholder="nome completo"
          label="Nome •"
          id="nome"
          name="nome"
          onChange={(e) => setName(e.target.value)}
          error={nameError}
        />
      </div>

      <Input
        type="email"
        placeholder="e-mail"
        label="E-mail •"
        id="email"
        name="email"
        onChange={(e) => setEmail(e.target.value)}
        error={emailError}
      />

      <div className="button-form">
        {sending ? (
          "Enviando email..."
        ) : (
          <button
            disabled={sending}
            type="submit"
            className={`button-submit orange`}
          >
            <img
              src={require("assets/img/buttons/right-orange@2x.png")}
              alt="Guardar"
            />
            <div className="title">Enviar</div>
          </button>
        )}
      </div>
    </form>
  )
}

export default AssessmentForm
