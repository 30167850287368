import React from "react"
import {
  getQuestionnaires,
  getPatients,
  sendQuestionnaire
} from "services/requests"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from "reactstrap"
import { Box, Questionnaires as QuestionnairesIcon } from "components/icons"
import Fade from "react-reveal/Fade"
import { withRouter } from "react-router-dom"
import { notify, throwErrorMessage } from "helpers"
import { Paginator, InputSelect } from "components/Common"
import ReactHtmlParser from "react-html-parser"

class Questionnaires extends React.Component {
  state = {
    current_page: 1,
    max_page: 1,
    loading: false,
    questionnaires: [],
    questionnaire_selected: { id: null },
    patients: [],
    patient_id: -1
  }
  componentDidMount() {
    this._fetchQuestionnaires()
    this._fetchPatients()
  }

  _fetchQuestionnaires = async (showBar = true) => {
    const { startPB, finishPB } = this.props
    await this.setState({ loading: true }, () => {
      if (showBar) {
        startPB()
      }
    })
    const { current_page } = this.state
    // get questionnaires
    await getQuestionnaires(current_page)
      .then((response) => {
        this.setState(
          {
            questionnaires: response.data,
            questionnaire_selected:
              this.state.questionnaire_selected.id === null
                ? response.data.length > 0
                  ? response.data[0]
                  : { id: null }
                : this.state.questionnaire_selected,
            max_page: response.meta.last_page,
            loading: false
          },
          () => {
            if (showBar) {
              finishPB()
            }
          }
        )
      })
      .catch((error) => {
        throwErrorMessage(error)
      })
  }

  _fetchPatients = async (showBar = true) => {
    const { startPB, finishPB } = this.props
    await this.setState({ loading: true }, () => {
      if (showBar) {
        startPB()
      }
    })
    // get patients
    await getPatients(null, "asc", "name", false)
      .then((response) => {
        this.setState(
          {
            patients: response.data,
            loading: false
          },
          () => {
            if (showBar) {
              finishPB()
            }
          }
        )
      })
      .catch((error) => {
        throwErrorMessage(error)
      })
  }

  _movePage = (dir) => {
    let { current_page } = this.state
    let value = dir === "left" ? current_page - 1 : current_page + 1
    this.setState({ current_page: value }, () => {
      this._fetchQuestionnaires(false) // should not simulate progress bar
    })
  }

  _selectQuestionnaire = (questionnaire) => {
    let { questionnaire_selected } = this.state

    if (questionnaire_selected.id !== questionnaire.id) {
      this.setState({ questionnaire_selected: questionnaire })
    }
  }

  _sendQuestionnaire = () => {
    if (this.state.patient_id !== -1) {
      sendQuestionnaire({
        patient_id: this.state.patient_id,
        reference: this.state.questionnaire_selected.reference
      })
        .then((response) => {
          notify("success", response.message)
        })
        .catch((response) => {
          notify("error", response.message)
        })
    } else {
      notify("error", "Tem que selecionar um paciente válido!")
    }
  }

  _onSelectPatientId = (e) => {
    this.setState({ patient_id: e.target.value })
  }

  _renderQuestionnaires = () => {
    const { questionnaires, current_page, max_page } = this.state
    if (questionnaires.length === 0) {
      return null
    }

    return (
      <>
        <ListGroup className="menu-app questionnaires" flush>
          {questionnaires.map((questionnaire, index) => {
            return (
              <button
                key={index}
                className={`button-menu${
                  this.state.questionnaire_selected.id === questionnaire.id
                    ? " active"
                    : ""
                }`}
                onClick={() => {
                  this._selectQuestionnaire(questionnaire)
                }}
              >
                <div className="container-icon">
                  <div className="svg-icon">
                    <QuestionnairesIcon />
                  </div>
                </div>
                <div className="text-button">
                  <span>•</span> Questionário {questionnaire.title}
                  <div className="section-text">{questionnaire.sub_title}</div>
                </div>
              </button>
            )
          })}
        </ListGroup>
        <Paginator
          color={"red"}
          currentPage={current_page}
          maxPage={max_page}
          movePage={this._movePage}
        />
      </>
    )
  }

  render() {
    const { color, isLoading, history } = this.props
    const { questionnaire_selected, patients } = this.state

    /*
    if (process.env.NODE_ENV === "production") {
      history.push("/soon");
    }*/

    if (isLoading) {
      return <div className="loading-div"></div>
    }
    return (
      <div className="position-relative">
        {/* shape Hero */}
        <section className={`section section-shaped section-app`}>
          <div className={`shape shape-style-1 shape-app ${color}`}>
            <span className="bottom-left-2" />
            <span className="bottom-right" />
          </div>
          {
            <Fade>
              <Container className="d-flex container-xl">
                <Row style={{ width: "100%" }}>
                  <div className="modal-icon">
                    <img
                      width="156px"
                      src={require("assets/img/modals-icons/questionnaire.png")}
                      alt="add-patient"
                    />
                  </div>
                  <Col lg="9" className="col-manual" md="9">
                    <h1 className="title">
                      <span className="red">•</span> Ferramentas de Avaliação
                    </h1>
                    <p className="content quest-tools">
                      Nesta secção estão disponíveis instrumentos de avaliação
                      psicológica, validados para a população portuguesa,
                      pertinentes para a monitorização clínica de sintomatologia
                      relevante nas perturbações do comportamento alimentar.
                      Quando possível, a avaliação sessão-a-sessão constitui,
                      tanto para o profissional de saúde como para o/a cliente,
                      uma importante ferramenta de feedback que permite, mais
                      contingentemente, detetar ganhos terapêuticos ou
                      deterioração.
                    </p>
                  </Col>
                  <Col
                    lg="9"
                    className="col-patient"
                    md="9"
                    style={{ marginTop: "47px" }}
                  >
                    <Row /*className="m-t-25"*/>
                      <Col className="records">
                        {questionnaire_selected.id != null && (
                          <>
                            <Card>
                              <CardBody className="questionnaire">
                                <p className="title">
                                  QUESTIONÁRIO SELECIONADO
                                </p>
                                <p>
                                  <span className="first">• </span>
                                  {questionnaire_selected.title}
                                  {questionnaire_selected.sub_title && (
                                    <>
                                      <span> • </span>
                                      <span style={{ fontWeight: "normal" }}>
                                        {questionnaire_selected.sub_title}
                                      </span>
                                    </>
                                  )}
                                </p>
                                <p
                                  className="description"
                                  style={{ fontSize: "12px" }}
                                >
                                  {ReactHtmlParser(
                                    questionnaire_selected.description
                                  )}
                                </p>
                              </CardBody>
                            </Card>
                            <br />
                            <Row style={{ maxWidth: "515px" }}>
                              <Col lg="10" md="10">
                                <InputSelect
                                  id="questionnaire_patient_id"
                                  value="-1"
                                  option_placeholder="Paciente que irá receber o questionário"
                                  label="Selecionar paciente •"
                                  options={patients}
                                  onChange={this._onSelectPatientId}
                                />
                              </Col>
                              <Col
                                lg="2"
                                md="2"
                                style={{
                                  marginTop: "30px",
                                  marginLeft: "-15px"
                                }}
                              >
                                <img
                                  src={require("assets/img/buttons/right-red.png")}
                                  alt="enviar"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this._sendQuestionnaire()}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                      <Col lg="5" sm="5" className="col-questionnaires">
                        <this._renderQuestionnaires />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Fade>
          }
          <div className="menu-app">
            <Fade>
              <button
                className="button-menu"
                onClick={() => history.push(`/app/patients`)}
              >
                <div className="container-icon">
                  <div className="svg-icon">
                    <Box />
                  </div>
                </div>
                <div className="text-button">
                  <span>•</span> Lista de Pacientes
                </div>
              </button>
              <button className="button-menu active">
                <div className="container-icon">
                  <div className="svg-icon">
                    <QuestionnairesIcon />
                  </div>
                </div>
                <div className="text-button">
                  <span>•</span> Ferramentas de Avaliação
                </div>
              </button>
            </Fade>
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(Questionnaires)
