let initialState = {
	authenticated:false,
	super_admin: false,
	access_token: '',
	id_user: '',
	name: '',
	email: '',
	img_url: '',
	job: '',
	address: '',
	color: '',
    permissions: [],
    role: ''
}
export default function reducer(state = initialState, {type,payload}) {
	switch (type) {
		case 'LOGIN_SUCCESS':
			return {
				...state,
				authenticated: payload.authenticated,
				super_admin: payload.super_admin,
				access_token: payload.access_token,
				id_user: payload.id_user,
				name: payload.name,
				email: payload.email,
				img_url: payload.img_url,
				job: payload.job,
				address: payload.address,
				color: payload.color,
                permissions: payload.permissions,
                role: payload.role
            };
		case 'SET_USER':
            return {
                ...state,
				authenticated: payload.authenticated,
				super_admin: payload.super_admin,
                id_user: payload.id_user,
                name: payload.name,
                email: payload.email,
				img_url: payload.img_url,
				job: payload.job,
				address: payload.address,
				color: payload.color,
                permissions: payload.permissions,
                role: payload.role
			};
		case 'UPDATED_PROFILE':
			return {
				...state,
				name: payload.name,
				img_url: payload.img_url,
				job: payload.job,
			}
		case 'UPDATED_AVATAR':
			return {
				...state,
				img_url: payload.img_url,
			}
		case 'LOGOUT':
			return {
				...state,
				authenticated: false,
				super_admin: false,
				access_token: '',
				id_user: '',
				name: '',
				email: '',
				img_url: '',
				job: '',
				address: '',
				color: '',
                permissions:[],
                role: ''
			};
		case 'TOKEN_REFRESHED':
			return {
				...state,
				access_token: payload.refreshed_token,
			};
		case 'APP_LOADED':
			return {
				...state,
				app_started: payload.app_started
			};
		default:
			return state;
	}
}