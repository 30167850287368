import React from 'react'
import useForm from 'react-hook-form'
import { Input } from "components/Common"
import { saveObservation } from 'services/requests'
import { notify, throwErrorMessage, formatPrideDate } from 'helpers'

const AddObservationForm = (props) => {
  const { register, handleSubmit, errors, setError } = useForm()
  const { color, toggleModal, reloadData, patient } = props;

  const onSubmit = (data) => {
    const { recordId } = props;
    data.record_id = recordId;
    saveObservation(data).then(response => {
      reloadData(recordId) // reload data on list view
      notify('success', response.message)
      toggleModal()
    }).catch(response => {
      throwErrorMessage(response, setError)
    })
  }

  const urlButton = require("assets/img/buttons/add-blue-2@2x.png");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <div className="patient-form-details">
            <div className="header">
              <h1 className="name">{patient.name}</h1>
              <h5 className="address">{patient.address}</h5>
            </div>
            <div className="details">
              <div className="item">
                <span>Profissão •</span> {patient.occupation}
              </div>
              <div className="item">
                <span>Data de Admissão •</span> {formatPrideDate(patient.date)}
              </div>
              <div className="item">
                <span>Qunadro Clínico •</span> estável
              </div>
            </div>
        </div>

        <Input type="textarea" rows={10} placeholder="Descreva a sua observação ao registo" label="Observações *" id="description" name="description" error={errors.description} register={register} />

        <div className="button-form">
            <button type="submit" className={`button-submit ${color}`}>
              <img src={urlButton} alt="login" />
              <div className="title">Guardar</div>
            </button>
        </div>
    </form>
  )
}

export default AddObservationForm;