import React from 'react'
import { FormGroup, Input as InputStrap, Label, FormText, Row } from "reactstrap";
import classnames from 'classnames'
import { Download, Pencil } from "components/icons";
import { downloadFile } from "services/requests";
import { throwErrorMessage } from "helpers";

const InputFile = ({ label, labelFile, id, name, value, onChange, accept, error, register, readOnly = false, show_change_icon = false, show_download_icon = false, icon_color = "#434343", style = {}, file = { name: null, download_url: null } }) => {
    const classNames = classnames({
        "has-error": typeof (error) !== 'undefined'
    })
    const _downloadClick = async (fileName, fileUrl) => {
        await downloadFile(null, fileName, fileUrl).catch(error => {
            throwErrorMessage(error);
        });
    }
    const _changeClick = () => {
        document.getElementById(id).click()
    }
    return (
        <FormGroup className="input-file-pride">
            {label &&
                <Label for={id}>{label}</Label>
            }
            <InputStrap type={readOnly ? 'text' : 'file'} name={name} value={value} className={classNames} id={id} onChange={onChange} innerRef={register} accept={accept} />

            <Label style={readOnly ? { ...style.label_file, cursor: "unset" } : style.label_file} for={id}>{labelFile}</Label>

            {show_change_icon && !readOnly &&
                <div title="Alterar" className="svg-icon" style={{ cursor: "pointer", display: "flex", marginLeft: "20px" }} onClick={() => _changeClick()}>
                    <Pencil fill={icon_color} />
                </div>
            }

            {show_download_icon &&
                <div title="Descarregar" className="svg-icon" style={{ cursor: "pointer", display: "flex", marginLeft: "20px" }} onClick={() => _downloadClick(file.name, file.url_download)}>
                    <Download fill={icon_color} />
                </div>
            }

            <FormText color="red">
                {typeof (error) !== 'undefined' &&
                    <span>{error.message}</span>
                }
            </FormText>
        </FormGroup>
    )
}

export { InputFile };