import React from "react";
import PropTypes from 'prop-types';
import { InputSubmit } from 'components/Common'
import { saveMessage } from "services/requests";
import { throwErrorMessage } from 'helpers'

class WriteMessage extends React.Component {
    state = {
        message: ''
    }
    _submitMessage = async() => {
        const { message } = this.state;
        const { chat_id, addMessage } = this.props;
        let data = {
            'content': message,
            'chat_id': chat_id
        }
        saveMessage(data).then(response => {
            this.setState({message: ''}, () => {
                // should add new message on the messages array
                addMessage(response)
            })
        }).catch(error => {
            throwErrorMessage(error)
        })
    }
    _handleKeyDown = (e) => {
        const { message } = this.state;
        if(e.key === 'Enter' && message !== '') {
            this._submitMessage()
        }
    }
    _onChange = (e) => {
        this.setState({message: e.target.value})
    }
    render() {
        const { message } = this.state;
        const { color, classNames } = this.props;
        return (
            <div className={classNames}>
                <InputSubmit color={color} value={message} size="sm" onKeyDown={this._handleKeyDown} onClick={this._submitMessage} onChange={this._onChange} placeholder="escrever mensagem..." />
            </div>
        )
    }
}

WriteMessage.propTypes = {
    color: PropTypes.string.isRequired,
    classNames: PropTypes.string,
    chat_id: PropTypes.number,
    addMessage: PropTypes.func.isRequired
}

export default WriteMessage;