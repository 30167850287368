import React from "react";
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from "reactstrap";
import { getTeam } from "services/requests_homepage";
import { isMobile, isTablet } from 'react-device-detect';

class Team extends React.Component {

    constructor(props) {
        super(props);
        this.state = { team: [] };
    }

    componentDidMount() {
        this._fetchTeam();
    }
    _fetchTeam = async () => {
        await getTeam().then(response => {
            this.setState({ team: response });
        })
    }
    _renderMembers = () => {
        const { team } = this.state;
        return team.map((t, index) => {
            return (
                <div className={(index % 2 === 0 ? `member m-t-80` : (isMobile && !isTablet ? `member m-t-80` : `member reverse m-t-80 m-b-95`))} key={`team-` + index}>
                    <Col className="col-md-30" sm="12">
                        <div className="marker">
                            <img src={t.avatar} alt={t.name} style={(index % 2 !== 0 && (!isMobile || isTablet)) ? {marginLeft: "40px"} : {}} className="img-fluid" />
                            <div className="overlay" />
                        </div>
                    </Col>
                    <Col>
                        <div className="details">
                            <div className="name">
                                {t.name}
                            </div>
                            <div className="job">
                                {t.info}
                            </div>
                            <div className="details">
                                {t.degree_level &&
                                    <>
                                        <b>grau académico: </b> {t.degree_level}
                                    </>
                                }
                                {t.degree_level && t.expertise &&
                                    <span> • </span>
                                }
                                {t.expertise &&
                                    <>
                                        <b>especialidade: </b> {t.expertise}
                                    </>
                                }
                                {/* {t.age &&
                                    <>
                                        <br />
                                        <b>idade: </b> {t.age + ` anos`}
                                    </>
                                } */}
                            </div>
                            <div className="description">
                                {t.bio}
                            </div>
                        </div>
                    </Col>
                </div>
            )
        })
    }
    render() {
        const { content } = this.props;
        return (
            <>
                
                    <div className="position-relative">
                        <section className="section section-shaped section-team" id="section-team">
                            {/* Background Shapes Pride */}
                            <div className="shape shape-style-1 shape-white pride-shape">
                                {/* <span className="span-150 top-left circle" /> */}
                                <span className="span-150 bottom-center grey" />
                                <span className="span-500 top-right right-dir" />
                            </div>

                            <Container className="shape-container d-flex align-items-center container-xl">
                                <Row style={{ width: '100%' }}>
                                    <Col lg="12" sm="12" className="section-header m-b-56">
                                        <h1 className="title">{content.title}</h1>
                                        <div className="subtitle">
                                            {this.state.team.length > 0 ? content.detail : `Não existe informação para apresentar.`}
                                        </div>
                                    </Col>
                                    <Col lg="12" sm="12" style={{paddingRight:0}}>
                                        <div className="team-members">
                                            <this._renderMembers />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </div>
                
            </>
        )
    }
}

export default Team;