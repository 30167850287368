import React, { memo } from 'react';

const AddPlus = memo(() => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="50" height="50" viewBox="0 0 50 50">
            <defs>
                <path id="a" d="M23.161 23.218V11.596c0-1.158.946-2.096 2.106-2.096 1.157 0 2.106.94 2.106 2.096l-.001 11.622h11.034c1.155 0 2.094.949 2.094 2.106a2.104 2.104 0 0 1-2.094 2.106l-11.034-.001v10.975a2.105 2.105 0 0 1-2.105 2.096 2.103 2.103 0 0 1-2.106-2.096V27.43H11.594A2.101 2.101 0 0 1 9.5 25.325c0-1.159.936-2.105 2.094-2.105l11.567-.001z"/>
                <path id="c" d="M0 0h48v48H0z"/>
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a"/>
                </mask>
                <use fill="#7C8091" fillRule="nonzero" xlinkHref="#a"/>
                <g mask="url(#b)">
                    <g transform="translate(1 1)">
                        <mask id="d" fill="#fff">
                            <use xlinkHref="#c"/>
                        </mask>
                        <path fill="#434343" d="M0 0h48v48H0z" mask="url(#d)"/>
                    </g>
                </g>
            </g>
        </svg>
    )
})

export { AddPlus }