import React from 'react';
import { FormGroup, Label, FormText } from "reactstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class RichTextEditor extends React.Component {

    constructor(props) {

        super(props)
        this.state = { text: props.defaultValue }

        this.quillRef = null;      // Quill instance
        this.reactQuillRef = null;

        this.handleChange = this.handleChange.bind(this)
        this.attachQuillRefs = this.attachQuillRefs.bind(this);
    }

    componentDidMount() {
        this.attachQuillRefs()
    }

    componentDidUpdate() {
        this.attachQuillRefs()
    }

    attachQuillRefs = () => {
        if (typeof this.reactQuillRef.getEditor !== 'function') return;
        this.quillRef = this.reactQuillRef.getEditor();
    }

    handleChange(value) {
        this.setState({ text: value });
        document.getElementById(this.props.hiddenId).value = value;
    }

    modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],                    // toggled buttons
            ['blockquote'],                                     // blocks
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],      // lists
            [{ 'indent': '-1' }, { 'indent': '+1' }],           // outdent/indent
            [{ 'header': [1, 2, 3, false] }],                   // header dropdown
            [{ 'align': [] }],                                  // text align
            ['link', 'image']
        ]
    }

    formats = [
        'header',
        'bold', 'italic', 'underline',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    render() {
        return (
            <FormGroup>
                {this.props.label &&
                    <Label for={this.props.id}>{this.props.label}</Label>
                }
                <ReactQuill
                    ref={(el) => { this.reactQuillRef = el }}
                    id={this.props.id}
                    theme="snow"
                    value={this.state.text}
                    onChange={this.handleChange}
                    modules={this.modules}
                    formats={this.formats}
                />
                <FormText color="red">
                    {typeof (this.props.error) !== 'undefined' &&
                        <span>{this.props.error.message}</span>
                    }
                </FormText>
            </FormGroup>
        )
    }
}

export { RichTextEditor }; 