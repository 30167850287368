import React, { useEffect } from 'react'
import { FormGroup, Input as InputStrap, Label, FormText } from "reactstrap";

const InputSelect = ({ label, id, name, value, error, register, defaultValue, options, option_placeholder, onChange, style }) => {

    useEffect(() => {
        if(id && defaultValue)
            document.getElementById(id).value = value == null ? defaultValue : value;
    }, [defaultValue, value, id ]);

    return (
        <FormGroup >
            {label &&
                <Label for={id}>{label}</Label>
            }
            <InputStrap type="select" name={name} id={id} style={style} innerRef={register} onChange={onChange}>
                {option_placeholder &&
                    <option value="-1" default defaultValue="-1">{option_placeholder}</option>
                }
                {options.map(o => {
                return <option key={o.id} value={o.id}>{o.name}{o.description && ` - ` + o.description}</option>
                })}
            </InputStrap>
            <FormText color="red">
                {typeof (error) !== 'undefined' &&
                    <span>{error.message}</span>
                }
            </FormText>
        </FormGroup>
    )
}

export { InputSelect };