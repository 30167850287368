import React from "react";
import classnames from 'classnames';
import { Avatar } from 'components/Common'
import PropTypes from 'prop-types';
import { InputSubmit } from 'components/Common'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Fade from 'react-reveal/Fade';
import { filterByValue } from 'helpers'

class Sidebar extends React.Component {
    state = {
        active: false,
        contacts: this.props.contacts,
        selected: this.props.userId
    }
    componentDidMount() {
        this.sb.addEventListener("wheel", this._disableScroll);
    }
    _toggleActive = (focus = false) => {
        this.setState({active: !this.state.active, contacts: this.props.contacts}, () => {
            this.props.onToggle()
            if(focus) {
                this.search.focus();
            }
        })
    }
    _disableScroll = (event) => {
        var deltaY = event.deltaY;
        var contentHeight = this.sb.scrollHeight;
        var visibleHeight = this.sb.offsetHeight;
        var scrollTop = this.sb.scrollTop;

        if (scrollTop === 0 && deltaY < 0)
            event.preventDefault();
        else if (visibleHeight + scrollTop === contentHeight && deltaY > 0)
            event.preventDefault();
    }
    _filterContacts = async(e) => {
        const keyword = e.target.value;
        let contacts = this.state.contacts;
        if(keyword.length > 2) {
            contacts = await filterByValue(contacts, keyword)
            this.setState({contacts})
        } else {
            this.setState({contacts: this.props.contacts})
        }
    }
    _selectUser = (id) => {
        const { fetchMessages } = this.props;
        this.setState({selected: id}, () => {
            fetchMessages(id, false)
        })
    }
    _renderContacts = () => {
        const { contacts, selected } = this.state;
        return contacts.map((contact) => {
            const classButton = classnames({
                'user': true,
                [contact.color]: true,
                'selected': (contact.id === selected)
            })
            return (
                <button className={classButton} key={contact.id} onClick={() => this._selectUser(contact.id)}>
                    <div className="user-avatar">
                        <Avatar url={contact.avatar} color={contact.color} size={63} />
                        <div className="overlay" />
                    </div>
                    <div className="details">
                        <div className="name">
                            <span>•</span> {contact.name}
                        </div>
                        <div className="job">
                            {contact.info}
                        </div>
                    </div>
                </button>
            )
        })
    }
    render() {
        const { active } = this.state;
        const { color } = this.props;
        const classNames = classnames({
            'chat-sidebar': true,
            'active': active,
             [color]: color
        })
        const searchIcon = require("assets/img/buttons/search-icon-" + color + "@2x.png");
        return (
            <>
                <div className={classNames}>
                    <div className="toggle-button">
                        <button onClick={() => this._toggleActive(false)} />
                    </div>
                    <div className="users-container">
                        <div className="search-area">
                            {!active ? (
                                <button onClick={() => this._toggleActive(true)}>
                                    <img src={searchIcon} alt="search" />
                                </button>
                            ) : (
                                <Fade right duration={100}>
                                    <InputSubmit color={color} placeholder="nome do contacto ..." size={"sm"} MainRef={el => this.search = el} onChange={this._filterContacts} />
                                </Fade>
                            )}
                        </div>
                        <PerfectScrollbar className="scrollable disable-scrolling" containerRef={el => this.sb = el}>
                            <this._renderContacts/>
                        </PerfectScrollbar>
                    </div>
                    <div className="transparent-box"></div>
                </div>
            </>
        )
    }
}

Sidebar.propTypes = {
    onToggle: PropTypes.func,
    color: PropTypes.string.isRequired,
    contacts: PropTypes.array.isRequired,
    fetchMessages: PropTypes.func.isRequired,
    userId: PropTypes.number
}

export default Sidebar;