import React, { memo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Avatar = memo(({url, color, size}) => {
    if(url) {
       return ( <LazyLoadImage src={url} alt="avatar" width={size} height={size} effect="blur" /> )
    } else {
        if(!color) {
            return null;
        }
        const urlPlaceholder =  require("assets/img/avatar/placeholder-" + color + "@2x.png");
        return (
            <LazyLoadImage src={urlPlaceholder} alt="avatar" width={size} height={size} effect="blur" />
        )
    }
})

export { Avatar };