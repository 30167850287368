import React from "react"
import { Container, Row, Col, ListGroup } from "reactstrap"
import { Paginator, Filters, Search } from "components/Common"
import { AddAdmin } from "components/icons"
import { getNotices, getNotice } from "services/requests_admin"
import { throwErrorMessage } from "helpers"
import Fade from "react-reveal/Fade"
import NoticeModal from "views/modals/admin/NoticeModal"
import NoticeItem from "views/admin/notices/NoticeItem"
import DeleteModal from "views/modals/admin/DeleteModal"

class Notices extends React.Component {
  state = {
    modalNotice: false,
    modalDelete: false,
    action: null,
    title: "",
    notices: [],
    itemDelete: null,
    notice: null,
    current_page: 1,
    max_page: 1,
    loading: false,
    filters: [
      // order flags
      { order: "desc", orderBy: "title", name: "título", selected: false },
      {
        order: "desc",
        orderBy: "created_at",
        name: "data de criação",
        selected: true
      },
      {
        order: "desc",
        orderBy: "publish_at",
        name: "data de publicação",
        selected: false
      }
    ],
    search: ""
  }

  componentDidMount() {
    this._fetchNotices()
  }

  _toggleModalNotice = async (title, action = null, id = null) => {
    if (action === "edit") {
      if (!this.state.modalNotice) {
        await getNotice(id)
          .then((response) => {
            this.setState({
              notice: response
            })
          })
          .catch((error) => {
            throwErrorMessage(error)
          })
      }
    } else {
      this.setState({
        notice: null
      })
    }

    this.setState({
      title: title,
      action: action,
      modalNotice: !this.state.modalNotice,
      search: ""
    })
  }

  _toggleModalDelete = (notice = null) => {
    this.setState({ modalDelete: !this.state.modalDelete })
    if (notice)
      this.setState({ itemDelete: { id: notice.id, name: "destaque" } })
  }

  _fetchNotices = async (showBar = true) => {
    const { startPB, finishPB } = this.props
    await this.setState({ loading: true }, () => {
      if (showBar) {
        startPB()
      }
    })
    const { current_page, filters } = this.state
    const filter = filters.find((el) => el.selected === true)
    // get notices
    await getNotices(
      current_page,
      filter.order,
      filter.orderBy,
      this.state.search
    )
      .then((response) => {
        this.setState(
          {
            notices: response.data,
            max_page: response.meta.last_page,
            loading: false
          },
          () => {
            if (showBar) {
              finishPB()
            }
          }
        )
      })
      .catch((error) => {
        throwErrorMessage(error)
      })
  }

  _movePage = (dir) => {
    let { current_page } = this.state
    let value = dir === "left" ? current_page - 1 : current_page + 1
    this.setState({ current_page: value }, () => {
      this._fetchNotices(false) // should not simulate progress bar
    })
  }

  _filterGrid = (key) => {
    let { filters } = this.state
    const filterIndex = filters.findIndex((el) => el.orderBy === key)
    // find current selected index
    const selectedIndex = filters.findIndex((el) => el.selected === true)

    filters[selectedIndex].selected = false
    filters[filterIndex].order =
      filters[filterIndex].order === "asc" ? "desc" : "asc"
    filters[filterIndex].selected = true

    this.setState({ filters }, () => {
      this._fetchNotices(false)
    }) // update state
  }

  _searchGird = (search) => {
    this.setState({ search })
    this._fetchNotices(false)
  }

  render() {
    const { notices, filters, modalNotice, current_page, max_page } = this.state
    const { color, isLoading } = this.props
    if (isLoading) {
      return <div className="loading-div"></div>
    }
    return (
      <div className="position-relative">
        {/* shape Hero */}
        <section className={`section section-shaped section-app`}>
          <div className={`shape shape-style-1 shape-app ${color}`}>
            <span className="bottom-left" />
            <span className="bottom-right" />
          </div>
          <Fade>
            <Container className="d-flex container-xl">
              <Row style={{ width: "100%" }}>
                <Col lg="10" className="col-admin" md="12">
                  <div className="filters-search">
                    <div className={`filters-admin ${color}`}>
                      <strong>ordenar por:</strong>
                      <Filters
                        filters={filters}
                        applyFilter={this._filterGrid}
                      />
                    </div>

                    <div className={`search-admin`}>
                      <strong>pesquisar por:</strong>
                      <Search
                        placeholder="título"
                        applySearch={this._searchGird}
                      />
                    </div>
                  </div>
                  <ListGroup className="list-admin" flush>
                    {notices.map((notice) => {
                      return (
                        <NoticeItem
                          notice={notice}
                          key={notice.id}
                          reloadData={this._fetchNotices}
                          toggleModal={this._toggleModalNotice}
                          toggleModalDelete={this._toggleModalDelete}
                          action="edit"
                          title={`Editar destaque`}
                        />
                      )
                    })}
                  </ListGroup>
                  <Paginator
                    color={color}
                    currentPage={current_page}
                    maxPage={max_page}
                    movePage={this._movePage}
                  />
                </Col>
              </Row>
            </Container>
          </Fade>
          <div className="menu-app-admin">
            <Fade>
              <button
                className="button-menu"
                onClick={() =>
                  this._toggleModalNotice("Adicionar destaque", "create")
                }
              >
                <div className="container-icon">
                  <div className="svg-icon">
                    <AddAdmin />
                  </div>
                </div>
                <div className="text-button">Adicionar destaque</div>
              </button>
            </Fade>
          </div>
        </section>
        {/* Modals */}
        <NoticeModal
          notice={this.state.notice}
          action={this.state.action}
          title={this.state.title}
          color={color}
          visible={modalNotice}
          toggleModal={this._toggleModalNotice}
          reloadData={this._fetchNotices}
        />
        <DeleteModal
          data={this.state.itemDelete}
          name="destaque"
          object="notices"
          visible={this.state.modalDelete}
          toggleModalDelete={this._toggleModalDelete}
          reloadData={this._fetchNotices}
        />
      </div>
    )
  }
}

export default Notices
