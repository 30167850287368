import React from "react";
import Fade from "react-reveal/Fade";
// reactstrap components
import {
  Container,
  Row,
  Col,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import Slider from "react-slick";
import { ShareMedia } from "components/Common";
import {
  ArrowUp as ArrowUpSvg,
  ArrowDown as ArrowDownSvg,
} from "components/icons";
import {
  getNoticesYears,
  getNoticesThumbnails,
  getNotice,
} from "services/requests_homepage";
import ReactHtmlParser from "react-html-parser";
import { formatPrideDate } from "helpers";
import classNames from "classnames";
import { Scrollbars } from "react-custom-scrollbars";
import { isMobile } from "react-device-detect";

function ArrowUp(props) {
  const { onClick } = props;
  return (
    <div className={"slick-events-next"} onClick={onClick}>
      <ArrowUpSvg />
    </div>
  );
}

function ArrowDown(props) {
  const { onClick } = props;
  return (
    <div className={"slick-events-prev"} onClick={onClick}>
      <ArrowDownSvg />
    </div>
  );
}

class Highlights extends React.Component {
  state = {
    years: [],
    active_year: null,
    thumbnails: [],
    active_notice_id: null,
    slidesToShow: 6,
    active: null, // active id of highlight
    notice: {},
  };

  componentDidMount() {
    this._fetchYears()
      .then(() => {
        this._setActiveYear();
      })
      .then(() => {
        this._fetchThumbnails(this.state.active_year);
      })
      .then(() => {
        this._fetchNotice(-1);
      });
  }

  _fetchYears = async () => {
    await getNoticesYears().then((response) => {
      this.setState({ years: response });
    });
  };

  _fetchThumbnails = async (year) => {
    await getNoticesThumbnails(year).then((response) => {
      let slidesToShow = response.length > 5 ? 6 : response.length;
      this.setState({ thumbnails: response, slidesToShow });
    });
  };
  _fetchNotice = async (id) => {
    await getNotice(id).then((response) => {
      this.setState({ notice: response });
    });
  };
  _setActiveYear(e, year = null, _fetchThumbnails = false) {
    if (e) e.preventDefault();

    if (year == null)
      year =
        this.state.years.length > 0
          ? this.state.years[0]
          : new Date().getFullYear();

    this.setState({ active_year: year });

    if (_fetchThumbnails) this._fetchThumbnails(year);
  }
  _setActiveNotice = async (e, id) => {
    if (e) e.preventDefault();
    if (id !== this.state.notice.id) {
      await this._fetchNotice(id);
      this.setState({ active: id });
    }
  };

  render() {
    const { content } = this.props;
    const { slidesToShow, notice, active } = this.state;
    const shareUrl = window.location.href;
    var settingsSlider = {
      infinite: false,
      speed: 500,
      slidesToShow,
      slidesToScroll: 1,
      //adapativeHeight: false,
      className: "highlights-slider",
      nextArrow: <ArrowDown />,
      prevArrow: <ArrowUp />,
      vertical: true,
    };
    return (
      <>
        <Fade>
          <div className="position-relative">
            <section
              className="section section-shaped section-highlights"
              id="section-highlights"
            >
              {/* Background Shapes Pride */}
              <div className="shape shape-style-1 shape-white pride-shape">
                <span className="span-170 top-right two-circles" />
                <span className="span-500 left-center" />
                <span className="span-300 bottom-left two-circles" />
                <span className="span-150 bottom-center red" />
              </div>
              <Container className="shape-container d-flex align-items-center container-xl">
                <Row>
                  <Col lg="12" sm="12" className="section-header m-b-56">
                    <h1 className="title">{content.title}</h1>
                    <div className="subtitle">
                      {this.state.thumbnails.length > 0
                        ? content.detail
                        : `Não existe informação para apresentar.`}
                    </div>
                  </Col>
                  {this.state.thumbnails.length > 0 && (
                    <>
                      <Col lg="7" sm="12" className="highlight-new">
                        <h1 className="title">
                          <span className="circle" />
                          {notice.title}
                        </h1>
                        <p className="date">
                          {ReactHtmlParser(
                            formatPrideDate(notice.publish_at, true)
                          )}
                        </p>
                        {!isMobile && (
                          <Scrollbars
                            className="body-new"
                            style={{
                              width: "100%",
                              maxHeight: 1040,
                              height: 200 * this.state.thumbnails.length,
                            }}
                          >
                            {ReactHtmlParser(notice.detail)}
                          </Scrollbars>
                        )}
                        {isMobile && (
                          <div className="body-new">
                            <div>{ReactHtmlParser(notice.detail)}</div>
                          </div>
                        )}
                        {this.state.years.length > 0 && (
                          <div className="share-button">
                            <button className="button-pop" id="tooltip-share">
                              <span className="icon">
                                <img
                                  src={require("assets/img/buttons/share.png")}
                                  alt="share"
                                />
                              </span>
                              <span className="text">Share</span>
                            </button>
                            <UncontrolledPopover
                              trigger="click"
                              placement="bottom"
                              target="tooltip-share"
                            >
                              <PopoverBody>
                                <ShareMedia
                                  url={shareUrl}
                                  title={notice.title}
                                  image={null}
                                />
                              </PopoverBody>
                            </UncontrolledPopover>
                          </div>
                        )}
                      </Col>
                      <Col lg="5" sm="12" className="highlight-filters">
                        {/* DATES */}
                        <div className="dates">
                          {this.state.years.map((year, index) => {
                            return (
                              <button
                                key={`event-year-` + index}
                                onClick={(e) =>
                                  this._setActiveYear(e, year, true)
                                }
                                className={
                                  year === this.state.active_year
                                    ? `date active`
                                    : `date`
                                }
                              >
                                {" "}
                                {year}{" "}
                              </button>
                            );
                          })}
                        </div>
                        <div className="highlight-container">
                          <Slider {...settingsSlider}>
                            {this.state.thumbnails.map((thumbnail, index) => {
                              const highlightEvent = classNames({
                                "highlight-event": true,
                                active: thumbnail.id === active,
                              });
                              return (
                                <button
                                  key={`event-` + index}
                                  className={highlightEvent}
                                  onClick={(e) =>
                                    this._setActiveNotice(e, thumbnail.id)
                                  }
                                >
                                  <div className="rounded-marked-img">
                                    <div className="marker">
                                      <img
                                        src={thumbnail.thumbnail}
                                        alt={"event"}
                                      />
                                      <div className="overlay" />
                                    </div>
                                  </div>
                                  <div className="details-event">
                                    <div className="title">
                                      <span className="circle" />
                                      {thumbnail.title}
                                    </div>
                                    <div className="date">
                                      {ReactHtmlParser(
                                        formatPrideDate(thumbnail.date, true)
                                      )}
                                    </div>
                                  </div>
                                </button>
                              );
                            })}
                          </Slider>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </Container>
            </section>
          </div>
        </Fade>
      </>
    );
  }
}

export default Highlights;
