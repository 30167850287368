import React from "react";
// reactstrap components
import { Container } from "reactstrap";
import LoginForm from "views/forms/LoginForm";
import PrideNavHomepage from "components/Navbars/PrideNavHomepage";
import { isMobile } from "react-device-detect";

class LoginAdmin extends React.Component {
  state = {
    details: { title: "Área do Administrador", subtitle: "", color: "admin" }
  };

  render() {
    const changeDetails = details => {
      this.setState({ details: details });
    };

    if (isMobile) {
      // redirect forbidden
      this.props.history.push("/forbidden");
    }

    return (
      <>
        <PrideNavHomepage showMenus={false} />
        <Container className="modal-dialog modal-dialog-centered modal-app">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                style={{ paddingTop: "15%", fontSize: "40px" }}
              >
                <span className="admin">•{this.state.details.title}</span>
              </h5>
              {this.state.details.subtitle && (
                <div className="subtitle" style={{ width: "100%" }}>
                  {this.state.details.subtitle}
                </div>
              )}
            </div>
            <div className="modal-body">
              <LoginForm
                color={this.state.details.color}
                changeDetails={changeDetails}
                details={this.state.details}
                fromAdmin="false"
              />
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default LoginAdmin;
