import React from "react";
import PropTypes from 'prop-types';
import { ListGroupItem } from "reactstrap";
import { Avatar } from "components/Common"
import { formatPrideDate } from 'helpers'
import { withRouter } from 'react-router-dom';

class UserItem extends React.Component {

    state = {
        user: this.props.user
    }

    render() {
        const { user, title, action, role, toggleModal, degree_levels  } = this.props;

        return (
            <ListGroupItem>
                <div className="main-column">
                    <div className="avatar-admin">
                        <Avatar url={user.avatar} color={user.color} size={63} />
                    </div>
                    <div className="admin-details">
                        <div className="name">{user.name}</div>
                        <div className="job">{user.info}</div>
                    </div>
                </div>
                <div className="separator-list"><span>•</span></div>
                <div className="state">
                    <div className="state-container">
                        <div className={`value ${user.color}`}>{user.role_label}</div>
                        <div className="show_on_team">{user.show_on_team === 1 ? `Visível na HomePage` : ``}</div>
                    </div>
                </div>
                <div className="separator-list"><span>•</span></div>
                <div className="date">
                    <div className="value">{formatPrideDate(user.date)}</div>
                    <div className="label">data de início</div>
                </div>
                <div className="separator-list"><span>•</span></div>
                <div className="state">
                    <div className="state-container">
                        <div className="value">{user.active_label}</div>
                        <div className="label">estado</div>
                    </div>
                </div>
                <div className="button">
                    <button title="Editar" className="button-admin" onClick={() => toggleModal(title, role, action, user.id, degree_levels)} />
                </div>
            </ListGroupItem>
        )
    }

}
UserItem.propTypes = {
    user: PropTypes.object.isRequired,
}

export default withRouter(UserItem);