import React, { Component } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  SetRefreshToken,
  SetProfile,
  SetLogout
} from "store/actions/auth.actions"
import routes from "./routes"
import PrideNavAdmin from "components/Navbars/PrideNavAdmin.jsx"
import { http } from "services"
import LoadingBar from "react-top-loading-bar"
import ReactNotification from "react-notifications-component"
import "react-notifications-component/dist/theme.css"

class AdminLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      boot: false,
      authenticated: false,
      user: {},
      isLoading: false
    }
  }
  componentDidMount() {
    this._checkAuth()
  }
  _checkAuth = async () => {
    let token = JSON.parse(window.localStorage.getItem("access_token"))
    if (token) {
      await this.props.SetRefreshToken(token)

      await http
        .get("auth/me")
        .then((response) => {
          this.setState(
            {
              authenticated: true,
              boot: true,
              user: response.data
            },
            () => {
              let role = response.data.roles[0]
              let super_admin = role === "super_admin" ? true : false
              if (!super_admin) {
                this._signOut()
              }
              this.props.SetProfile(
                true,
                super_admin,
                response.data.id,
                response.data.name,
                response.data.email,
                response.data.avatar,
                response.data.job,
                response.data.color,
                role
              )
            }
          )
        })
        .catch((error) => {
          this._signOut()
          console.log(error)
        })
    } else {
      this.setState({ boot: true }, () => {
        this.props.history.push("/")
      })
    }
  }
  _signOut = async () => {
    http.post("auth/logout").then((response) => {
      this.props.history.push("/")
      this.props.SetLogout()
    })
  }
  _startProgressBar = () => {
    this.LoadingBar.continuousStart()
    this.setState({ isLoading: true })
  }
  _finishProgressBar = () => {
    this.LoadingBar.complete()
    this.setState({ isLoading: false })
  }
  render() {
    const { user, boot, isLoading } = this.state
    if (!boot) {
      return null
    }
    return (
      <React.Fragment>
        <ReactNotification className={`notification-pride ${user.color}`} />
        <LoadingBar
          className={`loading-bar ${user.color}`}
          onRef={(ref) => (this.LoadingBar = ref)}
        />
        <PrideNavAdmin
          user={user}
          signOut={this._signOut}
          isLoading={isLoading}
        />
        <main ref="main">
          <Switch>
            {routes.map((route, idx) => {
              return route.component ? (
                <ProtectedRoute
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  protectedRoute={route.protected}
                  role={this.props.auth.role}
                  render={(props) => (
                    <route.component
                      {...props}
                      color={user.color}
                      startPB={this._startProgressBar}
                      finishPB={this._finishProgressBar}
                      isLoading={isLoading}
                    />
                  )}
                />
              ) : null
            })}
            <Redirect from="/admin" to="/admin/notices" />
          </Switch>
        </main>
      </React.Fragment>
    )
  }
}

const ProtectedRoute = ({ protectedRoute, role, ...rest }) =>
  protectedRoute === true ? <Route {...rest} /> : <Route {...rest} />

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { SetRefreshToken, SetProfile, SetLogout },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout)
