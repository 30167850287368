import React, { useState } from 'react'
import useForm from 'react-hook-form'
import { RichTextEditor } from "components/Common"
import { saveSection } from 'services/requests_admin'
import { notify, throwErrorMessage } from 'helpers'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';

const FooterForm = (props) => {

  const { register, handleSubmit, errors, setError } = useForm()
  const { color, toggleModal, reloadData, action, section } = props;
  const [activeTab, setActiveTab] = useState('Links');
  const [submited, setSubmited] = useState(false);
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const onSubmit = (data) => {
    setSubmited(true);
    data["content_type"] = "homepage";
    data['footers'] = [];
    section.footers.map((footer) => {
      let detail = document.getElementById('detail-' + footer.id).value;
      data['footers'].push({ 'id': footer.id, 'detail': (detail === "" ? footer.detail : detail) });

    });
    if (action === "edit") data["id"] = section.id;
    data["action"] = action;
    data["content_for"] = section.content_for;
    saveSection(data).then(response => {
      reloadData(false) // reload data on list view
      notify('success', response.message)
      toggleModal()
    }).catch(response => {
      throwErrorMessage(response, setError)
      setSubmited(false);
    })
  }

  const urlButton = require("assets/img/buttons/add-admin-2@2x.png");

  return (

    <form onSubmit={handleSubmit(onSubmit)}>

      <div>
        <Nav tabs className="nav-tabs-pride">

          {
            section.footers.map((footer, index) => {
              return (
                <NavItem key={`footer-` + footer.id}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === footer.title })}
                    onClick={() => { toggle(footer.title); }}
                  >
                    {footer.title}
                  </NavLink>
                </NavItem>
              )
            })
          }

        </Nav>

        <TabContent activeTab={activeTab}>

          {
            section.footers.map((footer, index) => {
              return (
                <TabPane key={`footer-` + footer.id} tabId={footer.title}>
                  <Row>
                    <Col sm="12">

                      <RichTextEditor defaultValue={action === 'edit' ? (footer.detail === null ? '' : footer.detail) : ''} hiddenId={`detail-` + footer.id} id={`detail-rte-` + footer.id} name={`detail-rte-` + footer.id} error={errors.detail} register={register} />

                      <input type="hidden" id={`detail-` + footer.id} name={`detail-` + footer.id} />

                    </Col>
                  </Row>
                </TabPane>
              )
            })
          }

        </TabContent>
      </div>

      <div className="button-form">
        <button disabled={submited} type="submit" className={`button-submit ${color}`}>
          <img src={urlButton} alt="guardar" />
          <div className="title">Guardar</div>
        </button>
      </div>
    </form>
  )
}

export default FooterForm;