import React from "react";
import PropTypes from "prop-types";
import { Questionnaires as QuestionnaireIcon } from "components/icons";
import { Card, CardHeader, Row, Col } from "reactstrap";
import { formatPrideDate } from "helpers";
import { withRouter } from "react-router";
import ReactHtmlParser from "react-html-parser";

class QuestionnaireItem extends React.Component {
  render() {
    const { questionnaire, toggleModal, role, q_todo_id } = this.props;
    return (
      <Card style={{ marginBottom: "1rem" }} key={questionnaire.id}>
        {questionnaire.done === 1 && (
          <>
            <CardHeader
              onClick={() =>
                toggleModal(
                  false,
                  role === "therapist" ? "results" : "chart",
                  questionnaire.reference,
                  q_todo_id
                )
              }
              className={`${role}`}
            >
              <div className="svg-icon">
                <QuestionnaireIcon width="40" height="40" />
              </div>
              <Row>
                <Col lg="5" className="name">
                  <span className="red">•</span> {questionnaire.name}
                </Col>
                <Col
                  lg="1"
                  style={{ marginLeft: "-30px", marginRight: "30px" }}
                >
                  <span>•</span>{" "}
                </Col>
                <Col lg="3" className="date" style={{ marginRight: "20px" }}>
                  {ReactHtmlParser(formatPrideDate(questionnaire.date, true))}
                </Col>
                <div className="arrow">
                  <img
                    style={{ width: "25px", height: "25px" }}
                    src={require("assets/img/shapes/questionnaire-done.png")}
                  />
                </div>
              </Row>
            </CardHeader>
          </>
        )}
        {questionnaire.done !== 1 && (
          <>
            <CardHeader
              onClick={
                role === "therapist"
                  ? null
                  : () =>
                      toggleModal(
                        false,
                        "todo",
                        questionnaire.reference,
                        q_todo_id
                      )
              }
              className={`not-done ${role}`}
            >
              <div className="svg-icon">
                <QuestionnaireIcon fill="#fff" width="40" height="40" />
              </div>
              <Row>
                <Col lg="5" className="name not-done">
                  <span className="not-done">•</span> {questionnaire.name}
                </Col>
                <Col
                  lg="1"
                  style={{ marginLeft: "-30px", marginRight: "30px" }}
                >
                  <span style={{ color: "#fff" }}>•</span>{" "}
                </Col>
                <Col
                  lg="3"
                  className="date not-done"
                  style={{ marginRight: "20px" }}
                >
                  {ReactHtmlParser(formatPrideDate(questionnaire.date, true))}
                </Col>
                <div className="arrow">
                  <img
                    style={{ width: "25px", height: "25px" }}
                    src={require("assets/img/shapes/questionnaire-not-done.png")}
                  />
                </div>
              </Row>
            </CardHeader>
          </>
        )}
      </Card>
    );
  }
}

QuestionnaireItem.propTypes = {
  questionnaire: PropTypes.object.isRequired
};

export default withRouter(QuestionnaireItem);
