import React, { memo } from 'react'

const AddButton = memo(({onPress, color, text}) => {

    const urlButton = require("assets/img/buttons/add-" + color + "-2@2x.png");

    return (
        <button className={`button-plus ${color}`} onClick={onPress}>
            <div className="icon-plus">
                <img src={urlButton} alt="plus" />
            </div>
            <div className="text">{text}</div>
        </button>
    )
})

export { AddButton };