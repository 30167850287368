import React from "react";
import PropTypes from "prop-types";
import { ListGroupItem } from "reactstrap";
import { formatPrideDate } from "helpers";
import { withRouter } from "react-router-dom";
import EllipsisText from "react-ellipsis-text";

class ArticleItem extends React.Component {
  render() {
    const { article, toggleArticle, mode } = this.props;
    return (
      <ListGroupItem
        onClick={() => toggleArticle(mode, article)}
        style={{ cursor: "pointer" }}
      >
        <div className="user" style={{ width: "40%" }}>
          <div className="icon-grid">
            <img
              src={require("assets/img/buttons/record-orange.png")}
              width="63"
              height="63"
              alt="record"
            />
          </div>
          <div className="user-details">
            <div className="name">
              {" "}
              <span>•</span>{" "}
              <EllipsisText
                className="title"
                text={article.title}
                length={30}
              />
            </div>
            <div className="job">título</div>
          </div>
        </div>
        <div className="separator-list">
          <span>•</span>
        </div>
        <div className="state">
          <div className="state-container">
            <div className="value">{article.name}</div>
            <div className="label">{article.creator}</div>
          </div>
        </div>
        <div className="separator-list">
          <span>•</span>
        </div>
        <div className="date">
          <div className="value">{formatPrideDate(article.date)}</div>
          <div className="label">data de entrada</div>
        </div>
        <div className="button-edit">
          <button
            className="button-patient"
            //onClick={() => toggleArticle(mode, article)}
          />
        </div>
      </ListGroupItem>
    );
  }
}
ArticleItem.propTypes = {
  article: PropTypes.object.isRequired,
  toggleArticle: PropTypes.func
};

export default withRouter(ArticleItem);
