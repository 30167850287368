import React from "react";
import { Container, Row, Col, ListGroup } from "reactstrap";
import { Paginator, Filters, Search } from "components/Common"
import { AddAdmin } from "components/icons"
import { getEvents, getEvent } from "services/requests_admin";
import { throwErrorMessage } from 'helpers'
import Fade from 'react-reveal/Fade';
import EventModal from 'views/modals/admin/EventModal';
import EventItem from 'views/admin/events/EventItem';
import DeleteModal from 'views/modals/admin/DeleteModal';

class Events extends React.Component {
    state = {
        modalEvent: false,
        modalDelete: false,
        action: null,
        title: '',
        event: null,
        events: [],
        itemDelete: null,
        current_page: 1,
        max_page: 1,
        loading: false,
        filters: [ // order flags
            { order: 'desc', orderBy: 'title', name: 'título', selected: false },
            { order: 'desc', orderBy: 'subject', name: 'tema', selected: false },
            { order: 'desc', orderBy: 'date', name: 'data da ação', selected: true },
            { order: 'desc', orderBy: 'cost', name: 'inscrição', selected: false },
            { order: 'desc', orderBy: 'openings', name: 'vagas', selected: false }
        ],
        search: ''
    }
    componentDidMount() {
        this._fetchEvents()
    }
    _toggleModalEvent = async (title, action = null, id = null) => {

        if (action === "edit") {
            if (!this.state.modalEvent) {
              await getEvent(id)
                .then((response) => {
                  this.setState({
                    event: response,
                  });
                })
                .catch((error) => {
                  throwErrorMessage(error);
                });
            }
          } else {
            this.setState({
                event: null,
            });
          }

        this.setState({ title: title, action: action, modalEvent: !this.state.modalEvent, search: "" });
    }
    _toggleModalDelete = (event = null) => {
        this.setState({ modalDelete: !this.state.modalDelete });
        if(event)
            this.setState({ itemDelete: { id: event.id, name: 'ação' } });
    } 
    _fetchEvents = async (showBar = true) => {
        const { startPB, finishPB } = this.props;
        await this.setState({ loading: true }, () => {
            if (showBar) { startPB() }
        })
        const { current_page, filters } = this.state;
        const filter = filters.find(el => el.selected === true);
        // get events
        await getEvents(current_page, filter.order, filter.orderBy, this.state.search).then(response => {
            this.setState({
                events: response.data,
                max_page: response.meta.last_page,
                loading: false
            }, () => {
                if (showBar) { finishPB() }
            })
        }).catch(error => {
            throwErrorMessage(error)
        })
    }
    _movePage = (dir) => {
        let { current_page } = this.state;
        let value = (dir === 'left') ? (current_page - 1) : (current_page + 1);
        this.setState({ current_page: value }, () => {
            this._fetchEvents(false) // should not simulate progress bar
        })
    }
    _filterGrid = (key) => {
        let { filters } = this.state;
        const filterIndex = filters.findIndex(el => el.orderBy === key);
        // find current selected index
        const selectedIndex = filters.findIndex(el => el.selected === true)

        filters[selectedIndex].selected = false;
        filters[filterIndex].order = (filters[filterIndex].order === 'asc') ? 'desc' : 'asc';
        filters[filterIndex].selected = true;

        this.setState({ filters }, () => {
            this._fetchEvents(false)
        }) // update state
    }
    _searchGird = (search) => {
        this.setState({search});
        this._fetchEvents(false);
    }
    render() {
        const { events, filters, modalEvent, current_page, max_page/*, loading*/ } = this.state;
        const { color, isLoading } = this.props;
        if (isLoading/* || loading*/) {
            return (<div className="loading-div"></div>)
        }
        return (
            <div className="position-relative">
                {/* shape Hero */}
                <section className={`section section-shaped section-app`}>
                    <div className={`shape shape-style-1 shape-app ${color}`}>
                        <span className="bottom-left" />
                        <span className="bottom-right" />
                    </div>
                    <Fade>
                        <Container className="d-flex container-xl">
                            <Row style={{ width: '100%' }}>
                                <Col lg="10" className="col-admin" md="12">
                                <div className="filters-search">
                                        <div className={`filters-admin ${color}`}>
                                            <strong>ordenar por:</strong>
                                            <Filters filters={filters} applyFilter={this._filterGrid} />
                                        </div>

                                        <div className={`search-admin`}>
                                            <strong>pesquisar por:</strong>
                                            <Search placeholder="título" applySearch={this._searchGird} />
                                        </div>
                                    </div>
                                    <ListGroup className="list-admin" flush>
                                        {events.map(event => {
                                            return (<EventItem event={event} key={event.id} reloadData={this._fetchEvents} toggleModal={this._toggleModalEvent} action="edit" title={`Editar ação`} toggleModalDelete={this._toggleModalDelete} />)
                                        })}
                                    </ListGroup>
                                    <Paginator color={color} currentPage={current_page} maxPage={max_page} movePage={this._movePage} />
                                </Col>
                            </Row>
                        </Container>
                    </Fade>
                    <div className="menu-app-admin">
                        <Fade>
                            <button className="button-menu" onClick={() => this._toggleModalEvent('Adicionar ação', 'create')}>
                                <div className="container-icon">
                                    <div className="svg-icon"><AddAdmin /></div>
                                </div>
                                <div className="text-button">
                                    Adicionar Ação
                                </div>
                            </button>
                        </Fade>
                    </div>
                </section>
                {/* Modals */}
                <EventModal event={this.state.event} action={this.state.action} title={this.state.title}  color={color} visible={modalEvent} toggleModal={this._toggleModalEvent} reloadData={this._fetchEvents} />
                <DeleteModal data={this.state.itemDelete} name="ação" object="events" visible={this.state.modalDelete} toggleModalDelete={this._toggleModalDelete} reloadData={this._fetchEvents}/>
            </div>
        )
    }
}

export default Events;