import React from "react";
import { Modal } from "reactstrap";
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import AddPatientForm from "views/forms/AddPatientForm";
import { Avatar } from "components/Common"

class AddPatient extends React.Component {
    state = {
        visible: false,
        avatar: null
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if(prevState.visible !== nextProps.visible) {
            return ({ visible: nextProps.visible })
        }
        return null;
    }
    _updateAvatar = (avatar) => {
        this.setState({avatar})
    }
    _openDialogAvatar = () => {
        document.getElementById('avatar-patient').click();
    }
    render() {
        const { visible, avatar } = this.state;
        if(visible === false) {
            return null;
        }
        const { toggleModal, color, reloadData } = this.props;
        return (
            <>
            {visible &&
                <Fade duration={300}>
                <button className="button-close-modal-pride" aria-label="Close" data-dismiss="modal" type="button" onClick={() => toggleModal()}>
                    <img src={require("assets/img/buttons/close.png")} alt="close" width={97} height={92} />
                </button>
                </Fade>
            }
            <Modal className="modal-dialog-centered modal-app" modalClassName="animated fadeIn" isOpen={visible} fade={false} backdrop="static" toggle={() => toggleModal()}>
                <div className="modal-icon">
                    <div className="avatar-container clickable" onClick={this._openDialogAvatar}>
                        <Avatar url={avatar} color="blue" size={182} />
                    </div>  
                </div>
                <div className="modal-header">
                    <h5 className="modal-title"><span className={color}>•</span>Adicionar Paciente</h5>
                </div>
                <div className="modal-body">
                    <AddPatientForm color="blue" toggleModal={toggleModal} reloadData={reloadData} updateAvatar={this._updateAvatar} />
                </div>
            </Modal>
            </>
        )
    }
}

AddPatient.propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    reloadData: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired
}

export default AddPatient