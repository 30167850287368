import React from "react";
import PropTypes from 'prop-types';
import { ListGroupItem } from "reactstrap";
import EllipsisText from "react-ellipsis-text";
import { withRouter } from 'react-router-dom';

class FaqItem extends React.Component {
     
    render() {
        const { title, action, faq, toggleModal, toggleModalDelete } = this.props;
        return (
            <ListGroupItem>
                <div className="main-column">
                    <div className="admin-details">
                        <div className="name"><EllipsisText text={faq.question} length={50}/></div>
                    </div>
                </div>   
                <div className="separator-list"><span>•</span></div>
                <div className="main-column">
                    <div className="state-container">
                        <div className="name"><EllipsisText text={faq.answer} length={75}/></div>
                    </div>
                </div>             
                <div className="separator-list"><span>•</span></div>
                <div className="state">
                    <div className="state-container">
                        <div className="value">{faq.active_label}</div>
                        <div className="label">estado</div>
                    </div>
                </div>
                <div className="button">
                    <button title="Editar" className="button-admin" onClick={() => toggleModal(title, action, faq)}/>
                </div>
                <div className="button">
                    <button title="Eliminar" className="button-admin delete" onClick={() => toggleModalDelete(faq)}/>
                </div>        
            </ListGroupItem>
        )
    }

}
FaqItem.propTypes = {
    faq: PropTypes.object.isRequired,
}

export default withRouter(FaqItem);