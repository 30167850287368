import React from "react"
import Fade from "react-reveal/Fade"
import { Container, Row, Col, FormGroup } from "reactstrap"
import classnames from "classnames"
import { InputSubmit } from "components/Common"
import { subscriveNewsletter } from "services/requests_homepage"
import { notify } from "helpers"

class Newsletter extends React.Component {
  constructor(props) {
    super(props)
    this.state = { email: "" }
    this._subscrive = this._subscrive.bind(this)
  }

  _subscrive(e) {
    if (this.state.email !== "") {
      subscriveNewsletter({ email: this.state.email })
      notify("success", "Newsletter subscrita.")
    }
    document.getElementById("newsletter_email").value = ""
  }

  render() {
    const { content } = this.props
    return (
      <>
        <Fade>
          <div className="position-relative">
            <section
              className="section section-shaped section-newsletter"
              id="section-newsletter"
            >
              <Container className="shape-container d-flex align-items-center container-lg">
                <Row style={{ minWidth: "100%" }} className="newsletter-row">
                  <Col className="text-center section-content" lg="12">
                    <p className="lead-large-title m-b-0">{content.title}</p>
                    <div className="subtitle m-t-24" style={{ width: "100%" }}>
                      {content.detail}
                    </div>
                    <div className="input-newsletter m-t-42">
                      <FormGroup
                        className={classnames({ focused: this.state.search })}
                      >
                        <InputSubmit
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          id="newsletter_email"
                          type="email"
                          placeholder="o seu e-mail"
                          onFocus={(e) => this.setState({ search: true })}
                          onBlur={(e) => this.setState({ search: false })}
                          classNames={"mb-4"}
                          onClick={this._subscrive}
                        />
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </Fade>
      </>
    )
  }
}
export default Newsletter
