import React from "react";
import PropTypes from 'prop-types';
import { ListGroupItem } from "reactstrap";
import { Avatar } from "components/Common"
import { formatPrideDate } from 'helpers'
import EllipsisText from "react-ellipsis-text";
import { withRouter } from 'react-router-dom';

class NoticeItem extends React.Component {
    
    render() {
        const { title, action, notice, toggleModal, toggleModalDelete } = this.props;
        return (
            <ListGroupItem>
                <div className="main-column">
                    <div className="avatar-admin">
                        <Avatar url={notice.thumbnail} color={notice.color} size={63} />
                    </div>
                    <div className="admin-details">
                        <div className="name"><EllipsisText text={notice.title} length={50} /></div>
                    </div>
                </div>
                <div className="separator-list"><span>•</span></div>
                <div className="date">
                    <div className="value">{formatPrideDate(notice.created_at)}</div>
                    <div className="label">criação</div>
                </div>
                <div className="separator-list"><span>•</span></div>
                <div className="date">
                    <div className="value">{formatPrideDate(notice.publish_at)}</div>
                    <div className="label">publicação</div>
                </div>
                <div className="separator-list"><span>•</span></div>
                <div className="state">
                    <div className="state-container">
                        <div className="value">{notice.active_label}</div>
                        <div className="label">estado</div>
                    </div>
                </div>
                <div className="button">
                    <button title="Editar" className="button-admin" onClick={() => toggleModal(title, action, notice.id)} />
                </div>
                <div className="button">
                    <button title="Eliminar" className="button-admin delete" onClick={() => toggleModalDelete(notice)}/>
                </div> 
            </ListGroupItem>
        )
    }

}
NoticeItem.propTypes = {
    notice: PropTypes.object.isRequired,
}

export default withRouter(NoticeItem);