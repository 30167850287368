import React, { useState } from "react";
import useForm from "react-hook-form";
import { Input } from "components/Common";
import { addTherapist } from "services/requests";
import { notify, throwErrorMessage } from "helpers";

const AddTherapistForm = (props) => {
  const { register, handleSubmit, errors, setError } = useForm();
  const [submited, setSubmited] = useState(false);
  const { color, toggleModal } = props;

  const onSubmit = (data) => {
    setSubmited(true);
    addTherapist(data)
      .then((response) => {
        notify("success", response.message);
        toggleModal();
      })
      .catch((response) => {
        setSubmited(false);
        throwErrorMessage(response, setError);
      });
  };

  const urlButton = require("assets/img/buttons/right-red@2x.png");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        type="text"
        placeholder="nome completo"
        label="Nome do Terapeuta *"
        id="name"
        name="name"
        error={errors.name}
        register={register}
      />

      <Input
        type="text"
        placeholder="descrever profissão"
        label="Profissão *"
        id="occupation"
        name="occupation"
        error={errors.occupation}
        register={register}
      />

      <Input
        type="number"
        placeholder="número cédula, ordem dos Psicólogos ou ordem dos médicos"
        label="Número de Cédula Profissional, Ordem de Psicólogos ou Ordem dos Médicos *"
        id="professional_certificate"
        name="professional_certificate"
        error={errors.professional_certificate}
        register={register}
      />

      <Input
        type="email"
        placeholder="e-mail"
        label="E-mail *"
        id="email"
        name="email"
        error={errors.email}
        register={register}
      />

      <Input
        type="text"
        placeholder="local de trabalho"
        label="Local de Trabalho *"
        id="workplace_address"
        name="workplace_address"
        error={errors.workplace_address}
        register={register}
      />

      <Input
        type="textarea"
        rows={5}
        placeholder="observações adicionais..."
        label="Observações"
        id="description"
        name="description"
        error={errors.description}
        register={register}
      />

      <div className="button-form">
        <button
          disabled={submited}
          type="submit"
          className={`button-submit ${color}`}
        >
          <img src={urlButton} alt="submit" />
          <div className="title">Enviar Pedido</div>
        </button>
      </div>
    </form>
  );
};

export default AddTherapistForm;
