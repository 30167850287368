import React from "react";
import { Container, Row, Col } from "reactstrap";
import YouTube from 'react-youtube';
import { Button } from 'components/Common'
import Fade from 'react-reveal/Fade';
// import { getSection } from "services/requests_homepage";

class Hero extends React.Component {
  _onVideoReady = (event) => {
    event.target.pauseVideo();
  }
  render() {
    const { supportProject, content } = this.props;
    const opts = {
      height: '420',
      width: '746',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        controls: 0,
        showinfo: 0,
        fs: 0
      }
    };
    return (
      <>
        <div className="position-relative">
          <section className="section section-lg section-hero section-shaped section-homepage">
            {/* Background Shapes Pride */}
            <div className="shape shape-style-1 shape-white pride-shape">
              {/* <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" /> */}
              <Fade><span className="span-150 bottom-center" /></Fade>
            </div>
            <Container className="shape-container d-flex align-items-center">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center content-hero" lg="12">
                    <img alt="..." className="img-fluid"
                      src={require("assets/img/brand/logotipo.png")}
                      style={{ width: "300px" }}
                    />
                    <p className="lead m-t-12 m-b-0">
                      {content.detail}
                    </p>
                    <p className="lead-title m-t-29 m-b-0">
                      {content.title}
                    </p>
                    {content.video_id != null &&
                      <div className="video-wrapper m-t-29">
                        <YouTube className={"video-hero"} videoId={content.video_id} opts={opts} onReady={this._onVideoReady} />
                      </div>
                    }
                    <div className="button-section m-t-28">
                      <Button onPress={() => supportProject()} text={"Apoiar Projeto PRIDE"} color={"orange"} fontSize={20}/>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        </div>
      </>
    );
  }
}

export default Hero;
