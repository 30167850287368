import React, { useState, useEffect } from 'react'
import useForm from 'react-hook-form'
import { Input, InputFile } from "components/Common"
import { updatePatient } from 'services/requests'
import { notify, throwErrorMessage } from 'helpers'
import { withRouter } from 'react-router';

const EditProfileForm = (props) => {
  const { register, handleSubmit, errors, setError } = useForm()
  const [submited, setSubmited] = useState(false)
  const { color, profile, role, updateAvatar, updateProfile, history } = props;
  const [ labelFile, setLabel ] = useState(profile !== null && typeof profile.curriculum_vitae !== 'undefined'
      ? (profile.curriculum_vitae.name != null
        ? profile.curriculum_vitae.name
        : "carregar cv")
      : "carregar cv");
  const [ avatar, setAvatar ] = useState(null);
  const [ file, setFile ] = useState(null)

  const onSubmit = (data) => {
    setSubmited(true);
    if(role === 'therapist') {
      data.cv = file; // set file of state
    }
    if(avatar) {
      data.avatar = avatar;
      delete data.file;
    }
    updatePatient(profile.id, data).then(response => {
      notify('success', response.message)
      updateProfile(response.user)
      history.goBack()
    }).catch(response => {
      throwErrorMessage(response, setError)
      setSubmited(false);
    })
  }
  const handleFile = (files) => {
    if(files[0]) {
      if(files[0].size < 10485760) { // lower than 5 mb
        setFile(files[0])
        setLabel(files[0].name)
      }
    }
  }
  const handleAvatar = (files) => {
    if(files[0]) {
      profile.avatar = URL.createObjectURL(files[0])
      setAvatar(files[0])
      updateAvatar(URL.createObjectURL(files[0]))
    }
  }

  const urlButton = require(`assets/img/buttons/right-${color}@2x.png`);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <Input type="textarea" rows={5} placeholder="Biografia do perfil..." label="Bio" id="bio" name="bio" error={errors.bio} register={register} defaultValue={profile.bio} />

        <Input type="text" placeholder="Telemóvel" label="Telemóvel *" id="phone" name="phone" error={errors.phone} register={register} defaultValue={profile.phone} />

        <Input type="email" placeholder="E-mail de autenticação" label="E-mail *" id="email" name="email" error={errors.email} register={register} defaultValue={profile.email} />

        <Input type="password" placeholder="Password" label="Password *" id="password" name="password" error={errors.password} register={register} defaultValue={profile.password} />

        <Input type="password" placeholder="Confirmação da password" label="Confirmar password *" id="password_confirmation" name="password_confirmation" error={errors.password_confirmation} register={register} />

        <Input type="text" placeholder="Morada" label="Morada" id="address" name="address" error={errors.address} register={register} defaultValue={profile.address} />

        {role === 'therapist' &&
          <InputFile file={profile != null && typeof profile.curriculum_vitae != 'undefined' ? profile.curriculum_vitae : null} style={{label_file:{color:"#b3021c"}}} icon_color="#b3021c" show_change_icon={true} show_download_icon={profile != null && profile.curriculum_vitae != null && profile.curriculum_vitae.url_download != null ? true : false} label="CV" labelFile={labelFile} id="file" name="file" error={errors.file} register={register} onChange={(e) => { handleFile(e.target.files)}} accept="application/pdf" />
        }

        <Input id="avatar-dialog" type="file" name="file" className="input-file" onChange={(e) => { handleAvatar(e.target.files)}} accept="image/jpeg, image/png" invisible={true}  />

        <div className="button-form">
          <button disabled={submited} type="submit" className={`button-submit ${color}`}>
            <img src={urlButton} alt="Guardar" />
            <div className="title">Guardar</div>
          </button>
        </div>
    </form>
  )
}

export default withRouter(EditProfileForm);