import React from 'react'
import useForm from 'react-hook-form'
import { Input } from "components/Common"
import { addSupport } from 'services/requests'
import { notify, throwErrorMessage } from 'helpers'

const AddSupportForm = (props) => {
  const { register, handleSubmit, errors, setError } = useForm()
  const { color, toggleModal } = props;

  const onSubmit = (data) => { 
    addSupport(data).then(response => {
      notify('success', 'Registado com sucesso.')
      toggleModal()
    }).catch(response => {
      throwErrorMessage(response, setError)
    })
  }

  const urlButton = require("assets/img/buttons/right-orange@2x.png");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <Input type="text" placeholder="nome completo" label="Nome *" id="name" name="name" error={errors.name} register={register} />

        <Input type="email" placeholder="e-mail" label="E-mail *" id="email" name="email" error={errors.email} register={register} />

        <Input type="textarea" rows={5} placeholder="observações adicionais..." label="Observações" id="description" name="description" error={errors.description} register={register} />

        <div className="button-form">
            <button type="submit" className={`button-submit ${color}`}>
              <img src={urlButton} alt="submit" />
              <div className="title">Enviar Apoio</div>
            </button>
        </div>
    </form>
  )
}

export default AddSupportForm;