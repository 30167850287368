import React, { memo } from 'react';

const CheckCircle = memo(() => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnXlink="http://www.w3.org/1999/xlink" width="13" height="13" viewBox="0 0 13 13">
            <defs>
                <path id="a" d="M6.5 0a6.5 6.5 0 1 1 0 13 6.5 6.5 0 0 1 0-13zm2.852 4.49a.812.812 0 0 0-1.149 0L5.47 7.222l-.674-.674a.813.813 0 0 0-1.148 1.15l1.248 1.247a.812.812 0 0 0 1.148 0l3.308-3.308a.812.812 0 0 0 0-1.149z"/>
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a"/>
                </mask>
                <g fill="#FF901B" mask="url(#b)">
                    <path d="M.1.1h12.8v12.8H.1z"/>
                </g>
            </g>
        </svg>
    )
})

export { CheckCircle }