import React from "react";
import PropTypes from "prop-types";
import { ListGroupItem } from "reactstrap";
import { formatPrideDate } from "helpers";
import { withRouter } from "react-router-dom";

class RecordItem extends React.Component {
  _navPatient = id => {
    this.props.history.push(`/app/patients/${id}`);
  };
  render() {
    const { record, toggleModalRecord } = this.props;
    return (
      <ListGroupItem
        style={{ cursor: "pointer" }}
        onClick={() => toggleModalRecord("edit", record)}
      >
        <div className="user">
          <div className="icon-grid">
            <img
              src={require("assets/img/buttons/record-blue.png")}
              width="63"
              height="63"
              alt="record"
            />
          </div>
          <div className="user-details">
            <div className="name">
              {" "}
              <span>•</span> {record.name}
            </div>
            <div className="job">número do registo</div>
          </div>
        </div>
        <div className="separator-list">
          <span>•</span>
        </div>
        <div className="date">
          <div className="value">{formatPrideDate(record.date)}</div>
          <div className="label">data de registo</div>
        </div>
        <div className="separator-list">
          <span>•</span>
        </div>
        <div className="state">
          <div className="state-container">
            {record.new_note ? (
              <div className="value">
                <span>•</span> nova observação <span className="notify">•</span>
              </div>
            ) : (
              <div className="value">
                <span>•</span> sem observações
              </div>
            )}
            <div className="label">observações terapeuta</div>
          </div>
        </div>
        <div className="button-edit">
          <button
            className="button-patient"
            onClick={() => toggleModalRecord("edit", record)}
          />
        </div>
      </ListGroupItem>
    );
  }
}

RecordItem.propTypes = {
  record: PropTypes.object.isRequired,
  toggleModalRecord: PropTypes.func.isRequired
};

export default withRouter(RecordItem);
