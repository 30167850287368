import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { withRouter } from "react-router-dom";

class PageMaintenance extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <>
        <div className="app">
          <div className="position-relative">
            {/* shape Hero */}
            <section
              className={`section section-shaped section-404`}
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <div className={`shape shape-style-1 shape-forbidden`}>
                <span className="top-right" />
                <span className="top-left" />
                <span className="middle-right" />
                <span className="middle-top" />
                <span className="bottom" />
                <span className="bottom-left" />
              </div>
              <Container className="container-forbidden">
                <Link to="/" className="logo">
                  <img
                    alt="logo"
                    src={require("assets/img/brand/logotipo.png")}
                  />
                </Link>
                <h5 className="message">Em manutenção. Por favor, tente mais tarde.</h5>                
              </Container>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(PageMaintenance);
