import React from "react";
import { Container, Row, Col, ListGroup } from "reactstrap";
import { Paginator, Filters, Search } from "components/Common";
import {
  AddPatient,
  AddTherapist,
  AddScientificCommunity
} from "components/icons";
import {
  getUser,
  getUsers,
  getDegreeLevels,
  getTherapists
} from "services/requests_admin";
import { throwErrorMessage } from "helpers";
import Fade from "react-reveal/Fade";
import UserModal from "views/modals/admin/UserModal";
import UserItem from "views/admin/users/UserItem";

class Users extends React.Component {
  state = {
    modalUser: false,
    action: null,
    title: "",
    role: null,
    users: [],
    degree_levels: [],
    therapists: [],
    user: null,
    current_page: 1,
    max_page: 1,
    loading: false,
    filters: [
      // order flags
      { order: "desc", orderBy: "name", name: "nome", selected: false },
      {
        order: "desc",
        orderBy: "created_at",
        name: "data de início",
        selected: true
      }
    ],
    search: ""
  };
  componentDidMount() {
    this._fetchUsers();
    this._fetchDegreeLevels();
    this._fetchTherapists();
  }
  _toggleModalUser = async (title, role, action, id = null) => {
    if (action === "edit") {
      if (!this.state.modalUser) {
        await getUser(id)
          .then(response => {
            this.setState({
              user: response
            });
          })
          .catch(error => {
            throwErrorMessage(error);
          });
      }
    } else {
      this.setState({
        user: null
      });
    }

    this.setState({
      title: title,
      role: role,
      action: action,
      modalUser: !this.state.modalUser,
      search: ""
    });
  };
  _fetchUsers = async (showBar = true) => {
    const { startPB, finishPB } = this.props;
    await this.setState({ loading: true }, () => {
      if (showBar) {
        startPB();
      }
    });
    const { current_page, filters } = this.state;
    const filter = filters.find(el => el.selected === true);
    // get users
    await getUsers(
      current_page,
      filter.order,
      filter.orderBy,
      this.state.search
    )
      .then(response => {
        this.setState(
          {
            users: response.data,
            max_page: response.meta.last_page,
            loading: false
          },
          () => {
            if (showBar) {
              finishPB();
            }
          }
        );
      })
      .catch(error => {
        throwErrorMessage(error);
      });
  };
  _fetchDegreeLevels = async () => {
    await getDegreeLevels()
      .then(response => {
        this.setState({ degree_levels: response });
      })
      .catch(error => {
        throwErrorMessage(error);
      });
  };
  _fetchTherapists = async () => {
    await getTherapists()
      .then(response => {
        this.setState({ therapists: response });
      })
      .catch(error => {
        throwErrorMessage(error);
      });
  };
  _movePage = dir => {
    let { current_page } = this.state;
    let value = dir === "left" ? current_page - 1 : current_page + 1;
    this.setState({ current_page: value }, () => {
      this._fetchUsers(false); // should not simulate progress bar
    });
  };
  _filterGrid = key => {
    let { filters } = this.state;
    const filterIndex = filters.findIndex(el => el.orderBy === key);
    // find current selected index
    const selectedIndex = filters.findIndex(el => el.selected === true);

    filters[selectedIndex].selected = false;
    filters[filterIndex].order =
      filters[filterIndex].order === "asc" ? "desc" : "asc";
    filters[filterIndex].selected = true;

    this.setState({ filters }, () => {
      this._fetchUsers(false);
    }); // update state
  };
  _searchGird = search => {
    this.setState({ search });
    this._fetchUsers(false);
  };
  render() {
    const { users, filters, modalUser, current_page, max_page } = this.state;
    const { color, isLoading } = this.props;
    if (isLoading) {
      return <div className="loading-div"></div>;
    }
    return (
      <div className="position-relative">
        {/* shape Hero */}
        <section className={`section section-shaped section-app`}>
          <div className={`shape shape-style-1 shape-app ${color}`}>
            <span className="bottom-left" />
            <span className="bottom-right" />
          </div>
          <Fade>
            <Container className="d-flex container-xl">
              <Row style={{ width: "100%" }}>
                <Col lg="10" className="col-admin" md="12">
                  <div className="filters-search">
                    <div className={`filters-admin ${color}`}>
                      <strong>ordenar por:</strong>
                      <Filters
                        filters={filters}
                        applyFilter={this._filterGrid}
                      />
                    </div>

                    <div className={`search-admin`}>
                      <strong>pesquisar por:</strong>
                      <Search
                        placeholder="nome"
                        applySearch={this._searchGird}
                      />
                    </div>
                  </div>
                  <ListGroup className="list-admin" flush>
                    {users.map(user => {
                      return (
                        <UserItem
                          role={user.roles[0]}
                          user={user}
                          degree_levels={this.state.degree_levels}
                          key={user.id}
                          reloadData={this._fetchUsers}
                          toggleModal={this._toggleModalUser}
                          action="edit"
                          title={`Editar ${user.role_label}`}
                        />
                      );
                    })}
                  </ListGroup>
                  <Paginator
                    color={color}
                    currentPage={current_page}
                    maxPage={max_page}
                    movePage={this._movePage}
                  />
                </Col>
              </Row>
            </Container>
          </Fade>
          <div className="menu-app-admin">
            <Fade>
              <button
                className="button-menu"
                onClick={() =>
                  this._toggleModalUser(
                    "Adicionar Terapeuta",
                    "therapist",
                    "create"
                  )
                }
              >
                <div className="container-icon">
                  <div className="svg-icon">
                    <AddTherapist />
                  </div>
                </div>
                <div className="text-button minimize-text red">
                  Adicionar Terapeuta
                </div>
              </button>
              <button
                className="button-menu"
                onClick={() =>
                  this._toggleModalUser(
                    "Adicionar Paciente",
                    "patient",
                    "create"
                  )
                }
              >
                <div className="container-icon">
                  <div className="svg-icon">
                    <AddPatient />
                  </div>
                </div>
                <div className="text-button minimize-text blue">
                  Adicionar Paciente
                </div>
              </button>
              <button
                className="button-menu"
                onClick={() =>
                  this._toggleModalUser(
                    "Adicionar Comunidade Ciêntifica",
                    "scientific_community",
                    "create"
                  )
                }
              >
                <div className="container-icon">
                  <div className="svg-icon">
                    <AddScientificCommunity />
                  </div>
                </div>
                <div className="text-button minimize-text orange">
                  Adicionar Comunidade Ciêntifica
                </div>
              </button>
            </Fade>
          </div>
        </section>
        {/* Modals */}
        <UserModal
          user={this.state.user}
          degree_levels={this.state.degree_levels}
          therapists={this.state.therapists}
          role={this.state.role}
          action={this.state.action}
          title={this.state.title}
          color={color}
          visible={modalUser}
          toggleModal={this._toggleModalUser}
          reloadData={this._fetchUsers}
        />
      </div>
    );
  }
}

export default Users;
