let initialState = {
	user_id: null, // the selected user to the chat
    chat_id: null, // save old chat id selected
}
export default function reducer(state = initialState, {type,payload}) {
	switch (type) {
		case 'SET_CHAT':
			return {
				...state,
				user_id: payload.user_id,
                chat_id: payload.chat_id,
            };
		case 'CLEAR_CHAT':
			return {
				...state,
				user_id: null,
				chat_id: null,
			};
		default:
			return state;
	}
}